import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SendIcon from "@mui/icons-material/Send";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

// context
import { DrawerContext } from "../../../context/drawerContext";

//Breadcrumb
import PBFEBreadcrumbs from "../../Elements/Breadcrumb/breadcrumb";
import { BORDER_COLOR, DRAWER_WIDTH } from "../../../constants/style";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmModal } from "../../Panel/Modal/confirmModal";
import { Button } from "@mui/material";
import {
  setIsError,
  setIsSuccess,
  setStateComponent,
} from "../../../redux/actionAppbarSlice";
import HistoryIcon from "../../../assets/icon/history.svg";
import RejectIcon from "../../../assets/icon/x_purple.svg";
import EditIcon from "../../../assets/icon/edit.svg";
import SaveIcon from "../../../assets/icon/save.svg";
import PlusWhiteIcon from "../../../assets/icon/plus_white.svg";
import WhiteRejectIcon from "../../../assets/icon/x_white.svg";
import PaperIcon from "../../../assets/icon/renew_purple.svg";
import ReloadIcon from "../../../assets/icon/return_purple.svg";
import CheckIcon from "../../../assets/icon/check_white.svg";
import ScbWaiting from "../../../assets/icon/waiting.svg";
import ScbYourrequest from "../../../assets/icon/yourrequest.svg";
import PlusIcon from "../../../assets/icon/plus.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import DuplicateIcon from "../../../assets/icon/duplicate.svg";
import makerCheckerService from "../../../api/makerCheckerService";
import { PBFERejectModal } from "../../Panel/Modal/rejectModal";
import { PBFECloseModal } from "../../Panel/Modal/closeModal";
import { AlertModal } from "../../Panel/Modal/alertModal";
import { UploadModal } from "../../Panel/Modal/uploadModal";
import { ButtonSubmitController } from "../../radium-omnibus/src/index";
import api from "../../../api";
import { alignment } from "export-xlsx";
import ExcelExport from "export-xlsx";
import {
  formatDateApi,
  isValidErrorApi,
  validateFormatDate,
} from "../../../utils/util";
import {
  CUSTOMER_FEE_PROFILE_REPORT,
  EMAIL_ALERT_REPORT_PATH_NAME,
  OVERDUE_REPORT_PATH_NAME,
  PATH_USER_GROUP_CREATE,
  PROFILE_PATH_USER_GROUP_NAME,
  REPORT_CUSTOMER_FEE_PROFILE,
  REPORT_EMAIL_ALERT,
  REPORT_FEE_PATH_NAME,
  REPORT_FEE_ROUTE,
  REPORT_OPERATION,
  REPORT_OPERATION_ROUTE,
  REPORT_OVERDUE,
  REPORT_PRICE_PATH_NAME,
  REPORT_PRICE_ROUTE,
  REPORT_PRICE_TXN_SUMMARY_PATH_NAME,
  REPORT_PRICE_TXN_SUMMARY_ROUTE,
  REPORT_FEE_PROFILE_PATH_NAME,
  REPORT_FEE_PROFILE_ROUTE,
  SETTINGS_PATH_NAME,
  TAX_SCHEME_TITLE,
} from "../../../constants/constant";
import uploadService from "../../../api/uploadService";
import reportService from "../../../api/reportService";
import settingService from "../../../api/settingService";
import userProfileService from "../../../api/userProfile";
import userGroupService from "../../../api/userGroupService";
import { HistoryModal } from "../../Panel/Modal/historyModal";
import serviceService from "../../../api/serviceService";
import offerService from "../../../api/offersService";
import offersBundleService from "../../../api/offersBundleService";
import { apiConfig } from "../../../config";
import useExportCsv from "../../../utils/useExportCsv";
// import { GenerateReportModal } from "../../Panel/Modal/generateReportModal";
// const SETTINGS_FOR_EXPORT = {
//   // Table settings
//   fileName: "Generate user profile report",
//   workSheets: [
//     {
//       sheetName: "Generate user profile report",
//       startingRowNumber: 1,
//       tableSettings: {
//         data: {
//           headerDefinition: [
//             {
//               name: "email",
//               key: "email",
//               width: 50,
//               style: { alignment: alignment.middleCenter },
//               groupKey: "all",
//             },
//             {
//               name: "status",
//               key: "status",
//               width: 20,
//               style: { alignment: alignment.middleCenter },
//               groupKey: "all",
//             },
//             {
//               name: "user_id",
//               key: "user_id",
//               width: 20,
//               style: { alignment: alignment.middleCenter },
//               groupKey: "all",
//             },
//             {
//               name: "user_name",
//               key: "user_name",
//               width: 50,
//               style: { alignment: alignment.middleCenter },
//               groupKey: "all",
//             },
//           ],
//         },
//       },
//     },
//   ],
// };

const RenderHistory = ({ isShowEditOffer, isShowEditService, code }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        sx={{ fontSize: "14px" }}
        startIcon={<img alt="" src={HistoryIcon}></img>}
      >
        HISTORY
      </Button>
      {open && (
        <HistoryModal
          isAppbar={true}
          headCell={[
            {
              id: "create_datetime",
              label: "Create Date",
            },
            {
              id: "create_user",
              label: "Request Name",
            },
            {
              id: "action",
              label: "Type",
            },
            {
              id: "remark",
              label: "Remark",
            },
          ]}
          header={["create_datetime", "create_user", "action", "remark"]}
          callbackNavigate={(e) => {
            if (isShowEditService) {
              navigate(
                `/service/history/${e.service_code}/${e.fee_service_history_id}`
              );
            } else if (isShowEditOffer) {
              navigate(`/offer/history/${e.offer_code}/${e.offer_history_id}`);
            } else {
              navigate(
                `/bundle/history/${e.offer_code}/${e.bndl_offer_history_id}`
              );
            }
          }}
          open={open}
          serviceApi={
            isShowEditService
              ? serviceService.getHistoryService
              : isShowEditOffer
              ? offerService.getHistoryOffer
              : offersBundleService.getHistoryOfferBundle
          }
          query={
            isShowEditService
              ? {
                  service_code: encodeURI(code),
                  offset: 0,
                  limit: 20,
                }
              : isShowEditOffer
              ? {
                  offer_code: encodeURI(code),
                  offset: 0,
                  limit: 20,
                }
              : {
                  offer_code: encodeURI(code),
                  offset: 0,
                  limit: 20,
                }
          }
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

const RenderButtonDownloadCSVUserProfile = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [download, setDownload] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const { onClickExport, CsvFilePanel } = useExportCsv({
    fileName: `User_Information_Report.csv`,
    headers: [
      { label: "Staff ID", key: "user_id" },
      { label: "Staff Name", key: "user_name" },
      { label: "Staff Email", key: "email" },
      { label: "Role", key: "role" },
      { label: "Status", key: "status" },
      { label: "Group Name", key: "group_name" },
    ],
    data: data,
  });

  React.useEffect(() => {
    if (open && data.length === 0) {
      FunctionLoadData();
    } else {
      return;
    }
  }, [open]);

  // React.useEffect(() => {
  //   if (download) {
  //     functionLoadCSV();
  //   } else {
  //     return;
  //   }
  // }, [download]);

  const FunctionLoadData = async () => {
    const { data } = await api.instanceCorp.get(
      `${apiConfig.getAllUserProfile}/download`
    );
    setData(data?.data);
  };

  // const functionLoadCSV = async () => {};

  return (
    <React.Fragment>
      <ConfirmModal
        open={open}
        title={""}
        isSuccess={isSuccess}
        isError={isError}
        onClose={() => {
          setOpen(false);
          // setIsSuccess(false);
          setIsError(false);
        }}
        message={errorMessage}
        text={`Would you like to generate user profile report ?`}
        onClick={async () => {
          try {
            // const formatData = [{ data: data }];
            // const excelExport = new ExcelExport();
            // await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, formatData);
            onClickExport();
            setOpen(false);
            // setIsSuccess(true);
            setIsError(false);
          } catch (error) {
            console.error("ERROR TO EXPORT DATA : ", error);
            setErrorMessage(error);
            setIsError(true);
            // setIsSuccess(false);
          }
        }}
        confirmText={"OK"}
      />
      <Button
        variant="text"
        sx={{ fontSize: "14px", marginRight: "20px" }}
        onClick={() => setOpen(true)}
        startIcon={<img alt="" src={PaperIcon}></img>}
      >
        GENERATE USER PROFILE
      </Button>
      <CsvFilePanel />
    </React.Fragment>
  );
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: DRAWER_WIDTH,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ActionAppbar = ({
  setShowConfirm,
  setOpenUpload,
  dataRequest,
  actionAppbar,
  editSetting,
  setEditSetting,
  reset,
  setReset,
  dataGenerateReport,
  settingsGlobal,
  setIsCreate,
  isCreate,
  setGroupSetting,
  groupSetting,
  setAction,
  action,
  dataUserGroup,
  dataUserProfile,
  setDataUserProfile,
  errorDesc,
  setErrorDesc,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { stateComponent, disabledAction, onClickBeforeAction } = useSelector(
    (state) => state.actionAppbar
  );

  const { user } = useSelector((state) => state.auth);

  const isMaker = dataRequest?.maker === user?.user_name;

  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  // const [loadingRequestId, setLoadingRequestId] = useState(false);
  // const [loadingClose, setLoadingClose] = useState(false);
  // const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingUserProfile, setLoadingUserProfile] = useState(false);
  const [loadingGroup, setLoadingGroup] = useState(false);

  const [typeReject, setTypeReject] = useState("");
  const [openReject, setOpenReject] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [openClose, setOpenClose] = useState(false);
  const [closeReason, setCloseReason] = useState("");

  const [returnErrorApi, setReturnErrorApi] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  // const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    setTypeReject("");
    setOpenReject(false);
    setRejectReason("");
    setTypeReject("");
    setOpenClose(false);
    setCloseReason("");
    setReturnErrorApi("");
    setOpenError(false);
    setOpenSuccess(false);
    setErrorDesc(false);
    setEditSetting(false);
    setAction("");
  }, [location.pathname]);

  useEffect(() => {
    setOpenError(false);
  }, [actionAppbar]);

  // useEffect(() => {
  //   setTypeReject("");
  //   setOpenReject(false);
  //   setRejectReason("");
  //   setTypeReject("");
  //   setOpenClose(false);
  //   setCloseReason("");
  //   setReturnErrorApi("");
  // }, []);

  const onClickUserProfile = async () => {
    try {
      let tempUserRole = [];
      let checkUserRole = [];
      let newData = {
        reason: dataUserProfile.reason,
        status: dataUserProfile.status,
        user_role: [],
      };

      if (
        ["System Admin", "Security Admin"].includes(dataUserProfile.roles.name)
      ) {
        const user_role =
          dataUserProfile.roles.name === "System Admin"
            ? "SYSTEMADMIN"
            : "SECURITYADMIN";
        tempUserRole = [{ user_group_id: 0, user_role: user_role }];
      } else {
        dataUserProfile?.roles?.value?.forEach((val) => {
          val.action.forEach((action) => {
            if (action.checked) {
              tempUserRole = [
                ...tempUserRole,
                { user_group_id: val.user_group_id, user_role: action.value },
              ];
            }
          });
        });

        dataUserProfile?.roles?.value
          ?.filter((ev) => ev.checked)
          .forEach((ev) => {
            const checkAction = ev.action.every((action) => {
              return !action.checked;
            });

            checkUserRole = [...checkUserRole, checkAction];
          });
      }

      newData.status = _.upperCase(newData.status);
      newData.user_role = tempUserRole;

      if (
        !dataUserProfile?.reason ||
        !dataUserProfile?.user_id ||
        !tempUserRole.length ||
        checkUserRole.some((ev) => ev === true) ||
        dataUserProfile.reason.length > 250
      ) {
        setErrorDesc(true);
        return;
      }

      setLoadingUserProfile(true);
      let checkSuccess = false;
      if (actionAppbar === "USERPROFILE_EDIT") {
        const { data } = await userProfileService.updateUserProfileById(
          dataUserProfile.user_id,
          newData
        );
        checkSuccess = data?.status?.description === "Success";
      } else {
        newData.user_id = dataUserProfile.user_id;
        newData.user_name = dataUserProfile.user_name;
        newData.email = dataUserProfile.email;
        const { data } = await userProfileService.postUserProfile(newData);
        checkSuccess = data?.status?.description === "Success";
      }

      if (checkSuccess) {
        setOpenSuccess(true);
      }
    } catch (error) {
      if (isValidErrorApi(error)) {
        setReturnErrorApi(error.response.data.status.description);
        setOpenError(true);
      }
      console.log("generate error", error);
      console.log("create user profile error", error);
    } finally {
      setLoadingUserProfile(false);
    }
  };

  const onClickEditSetting = async () => {
    if (editSetting) {
      try {
        const validate = settingsGlobal?.filter(
          (item) => item.setting_value === ""
        );

        if (validate?.length > 0) {
          return;
        } else {
          await settingService.putSystemService(settingsGlobal);
          setEditSetting(false);
        }
      } catch (error) {
        console.error("ERROR TO UPDATE SETIING : ", error);
      }
    } else {
      setEditSetting(true);
    }
  };

  const FormatUserGroupToApi = (data) => {
    return {
      user_group_name: isCreate
        ? `${String(data.user_group_name).trim()}`
        : `${String(data.name).trim()}`,
      service: data.service,
    };
  };

  const onClickEditUserGroupService = async () => {
    if (groupSetting || isCreate) {
      try {
        if (dataUserGroup?.error?.error) {
          console.log("IN ERROR 1");
          setErrorDesc(true);
          return;
        } else {
          if (dataUserGroup?.service?.length > 0) {
            setLoadingGroup(true);
            if (dataUserGroup.id) {
              console.log("IN UPDATE ");
              await userGroupService.putUserGroup(
                dataUserGroup.id,
                FormatUserGroupToApi(dataUserGroup)
              );
              window.location.reload();
            } else {
              await userGroupService.postUserGroup(
                FormatUserGroupToApi(dataUserGroup)
              );
              window.location.reload();
            }
          } else {
            setErrorDesc(true);
            return;
          }
        }
      } catch (error) {
        console.error("GROUP ERROR : ", error);
        if (isValidErrorApi(error)) {
          setReturnErrorApi(error?.response?.data?.status?.description);
          setOpenError(true);
        }
      } finally {
        setLoadingGroup(false);
      }
    } else {
      setGroupSetting(true);
    }
  };

  const getReportType = () => {
    const splitPath = location.pathname.split("/");
    if (splitPath[1] === "pricedTransaction") return "PRICED_TRANSACTION";
    if (splitPath[1] === "feeDebit") return "FEE_DEBIT";
    if (splitPath[1] === "emailAlert") return "EMAIL_ALERT";
    if (splitPath[1] === "overdue") return "OVERDUE";
    if (splitPath[1] === "customerFeeProfile") return "ACCOUNT_STATUS";
    if (splitPath[1] === "operation") return "OPERATION";
    if (splitPath[1] === "feeProfile") return "FEE_PROFILE";
    return "PRICED_TXN_SUMMARY";
  };

  const onClickGenerate = async () => {
    try {
      let newFromDate = new Date(dataGenerateReport.calendarValue[0]);
      let newToDate = new Date(dataGenerateReport.calendarValue[1]);
      if (
        newFromDate.getTime() > newToDate.getTime() ||
        newToDate.getTime() < newFromDate.getTime() ||
        validateFormatDate(newFromDate) ||
        validateFormatDate(newToDate)
        // getCheckValidateThreeMonth(newFromDate, newToDate)
      ) {
        setOpenError(true);
        setReturnErrorApi("Invalid Date");
        return;
      }

      setLoadingGenerate(true);
      let report_type = getReportType();

      const { data } = await reportService.generateReport({
        report_type,
        criteria: {
          customers: dataGenerateReport.customer,
          accounts:
            dataGenerateReport.account.length 
              ? dataGenerateReport.account
              : dataGenerateReport.fee_account,
          service_codes: dataGenerateReport.service,
          charge_codes: dataGenerateReport.charge,
          maker_ids: dataGenerateReport.maker,
          checker_ids: dataGenerateReport.checker,
          from_date: formatDateApi(dataGenerateReport.calendarValue[0]),
          to_date: formatDateApi(dataGenerateReport.calendarValue[1]),
          fee_account_status:
            dataGenerateReport.feeAccount === "All Status"
              ? null
              : dataGenerateReport.feeAccount,
          debit_account_status:
            dataGenerateReport.feeDebit === "All Status"
              ? null
              : dataGenerateReport.feeDebit, //dataGenerateReport.feeDebit,
          status:
            dataGenerateReport.reportStatus === "All Status"
              ? null
              : dataGenerateReport.reportStatus,
        },
      });

      if (data.status.description === "Success") {
        setOpenSuccess(true);
      }
    } catch (error) {
      if (isValidErrorApi(error)) {
        setReturnErrorApi(error.response.data.status.description);
        setOpenError(true);
      }
      console.log("generate error", error);
    } finally {
      setLoadingGenerate(false);
    }
  };

  const onClickCloseOpenSuccess = () => {
    setOpenSuccess(false);
    window.location.reload();
  };

  const onClickSetPrimaryDisplay =
    (serviceType = "") =>
    () => {
      if (
        serviceType === "checker" &&
        getPrimaryDisplay("checker") === "ALL REQUEST"
      ) {
        return navigate("/waitingforapprove/allrequest");
      } else if (
        serviceType === "checker" &&
        getPrimaryDisplay("checker") === "Waiting for approval"
      ) {
        return navigate("/waitingforapprove");
      }

      if (
        serviceType === "maker" &&
        getPrimaryDisplay("maker") === "ALL REQUEST"
      ) {
        return navigate("/myrequest/allrequest");
      } else if (
        serviceType === "maker" &&
        getPrimaryDisplay("maker") === "MY REQUEST"
      ) {
        return navigate("/myrequest");
      }
    };

  const getPrimaryDisplay = (serviceType) => {
    const splitPathName = location.pathname.split("/");

    if (serviceType === "checker" && splitPathName.length === 3) {
      return "Waiting for approval";
    } else if (serviceType === "checker" && splitPathName.length === 2) {
      return "ALL REQUEST";
    }

    if (serviceType === "maker" && splitPathName.length === 3) {
      return "MY REQUEST";
    } else if (serviceType === "maker" && splitPathName.length === 2) {
      return "ALL REQUEST";
    }
  };

  if (actionAppbar === "USERPROFILE_EDIT") {
    return (
      <div>
        <AlertModal
          open={openSuccess}
          title={"User Profile"}
          description={"Update User Profile Success"}
          action={() => onClickCloseOpenSuccess()}
        />
        {user?.menu?.admin === "E" && (
          <div>
            {editSetting ? (
              <LoadingButton
                startIcon={<img alt="" src={SaveIcon} />}
                variant="contained"
                sx={{ fontSize: "14px" }}
                onClick={onClickUserProfile}
                loading={loadingUserProfile}
                disabled={loadingUserProfile}
              >
                SAVE
              </LoadingButton>
            ) : (
              <Button
                onClick={() => setEditSetting(true)}
                variant="text"
                sx={{ fontSize: "14px" }}
                startIcon={<img alt="" src={EditIcon} />}
              >
                EDIT
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }

  if (actionAppbar === "USERPROFILE_CREATE") {
    return (
      <div>
        <AlertModal
          open={openSuccess}
          title={"User Profile"}
          description={"Create User Profile Success"}
          action={() => onClickCloseOpenSuccess()}
        />
        <AlertModal
          open={openError}
          title={"User Profile"}
          description={returnErrorApi}
          action={() => setOpenError(false)}
        />

        {user?.menu?.admin === "E" && (
          <div className="flex">
            <RenderButtonDownloadCSVUserProfile />
            <div>
              {isCreate ? (
                <Button
                  startIcon={<img alt="" src={SaveIcon} />}
                  variant="contained"
                  sx={{ fontSize: "14px" }}
                  onClick={onClickUserProfile}
                  loading={loadingUserProfile}
                  disabled={loadingUserProfile}
                >
                  SAVE
                </Button>
              ) : (
                <Button
                  onClick={() => setIsCreate(true)}
                  startIcon={
                    <img alt="" className="text-white" src={PlusWhiteIcon} />
                  }
                  variant="contained"
                  sx={{ fontSize: "14px" }}
                >
                  CREATE
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  if (actionAppbar === "MY_REQUEST") {
    return (
      <Button
        variant="text"
        sx={{ fontSize: "14px" }}
        onClick={onClickSetPrimaryDisplay("maker")}
        startIcon={<img alt="" src={true ? HistoryIcon : ScbYourrequest}></img>}
      >
        {getPrimaryDisplay("maker")}
      </Button>
    );
  }

  if (actionAppbar === "UPLOAD DATA") {
    return (
      <div>
        {user?.menu?.data_upload === "E" && (
          <Button
            variant="text"
            sx={{ fontSize: "14px" }}
            onClick={() => setOpenUpload(true)}
            startIcon={<img alt="" src={PlusIcon}></img>}
          >
            UPLOAD DATA
          </Button>
        )}
      </div>
    );
  }

  const pathOfWebSite = location.pathname.split("/");

  const isShowEditService =
    pathOfWebSite.length === 3 && pathOfWebSite[1] === "service";

  const isShowNewService =
    pathOfWebSite.length === 2 && pathOfWebSite[1] === "service";

  const isShowSaveCreateService =
    pathOfWebSite.length === 3 &&
    pathOfWebSite[1] === "service" &&
    pathOfWebSite[2] === "create";

  const isShowNewOffer =
    pathOfWebSite.length === 2 && pathOfWebSite[1] === "offer";

  const isShowEditOffer =
    pathOfWebSite.length === 3 && pathOfWebSite[1] === "offer";

  const isShowSaveCreateOffer =
    pathOfWebSite.length === 3 &&
    pathOfWebSite[1] === "offer" &&
    pathOfWebSite[2] === "create";

  const isShowEditBundle =
    pathOfWebSite.length === 3 && pathOfWebSite[1] === "bundle";

  const isShowNewBundle =
    pathOfWebSite.length === 2 && pathOfWebSite[1] === "bundle";

  const isShowSaveCreateBundle =
    pathOfWebSite.length === 3 &&
    pathOfWebSite[1] === "bundle" &&
    pathOfWebSite[2] === "create";

  const code = pathOfWebSite[pathOfWebSite.length - 1];
  if (user?.menu?.design === "E" && isShowNewOffer) {
    return (
      <div>
        <Button
          onClick={() => {
            navigate("/offer/create");
          }}
          startIcon={<img alt="" className="text-white" src={PlusWhiteIcon} />}
          variant="contained"
          sx={{ fontSize: "14px" }}
        >
          CREATE
        </Button>
      </div>
    );
  }
  if (user?.menu?.design === "E" && isShowNewService) {
    return (
      <div>
        <Button
          onClick={() => {
            navigate("/service/create");
          }}
          startIcon={<img alt="" className="text-white" src={PlusWhiteIcon} />}
          variant="contained"
          sx={{ fontSize: "14px" }}
        >
          CREATE
        </Button>
      </div>
    );
  }
  if (user?.menu?.design === "E" && isShowNewBundle) {
    return (
      <div>
        <Button
          onClick={() => {
            navigate("/bundle/create");
          }}
          startIcon={<img alt="" className="text-white" src={PlusWhiteIcon} />}
          variant="contained"
          sx={{ fontSize: "14px" }}
        >
          CREATE
        </Button>
      </div>
    );
  }

  if (isShowEditService || isShowEditOffer || isShowEditBundle) {
    if (
      action === "EDIT" ||
      isShowSaveCreateService ||
      isShowSaveCreateOffer ||
      isShowSaveCreateBundle
    ) {
      return (
        <ButtonSubmitController
          render={(props) => {
            return (
              <Button
                startIcon={<img alt="" src={SaveIcon} />}
                variant="contained"
                sx={{ fontSize: "14px" }}
                onClick={() => {
                  props.click();
                }}
                loading={action === ""}
              >
                SAVE
              </Button>
            );
          }}
        />
      );
    } else {
      return (
        <div className="flex">
          <RenderHistory
            isShowEditService={isShowEditService}
            isShowEditOffer={isShowEditOffer}
            isShowEditBundle={isShowEditBundle}
            code={code}
          />

          {user?.menu?.design === "E" && (
            <Button
              onClick={() => setAction("EDIT")}
              variant="text"
              sx={{ fontSize: "14px" }}
              startIcon={<img alt="" src={EditIcon} />}
            >
              EDIT
            </Button>
          )}
        </div>
      );
    }
  }

  if (location?.pathname)
    if (user?.menu?.design === "E" && actionAppbar === TAX_SCHEME_TITLE) {
      /// Create
      return (
        <Button
          onClick={() => setAction(TAX_SCHEME_TITLE)}
          startIcon={<img alt="" className="text-white" src={PlusWhiteIcon} />}
          variant="contained"
          sx={{ fontSize: "14px" }}
        >
          CREATE
        </Button>
      );
    }

  /// Edit
  if (
    user?.menu?.design === "E" &&
    actionAppbar === "TAX SCHEME SELECT" &&
    !location.pathname.includes("history")
  ) {
    return (
      <Button
        onClick={() => setAction("TAX SCHEME EDIT")}
        variant="text"
        sx={{ fontSize: "14px" }}
        startIcon={<img alt="" src={EditIcon} />}
      >
        EDIT
      </Button>
    );
  }

  /// Submit EDIT / CREATE
  if (actionAppbar === "CREATE TAX SCHEME") {
    return (
      <Button
        startIcon={<img alt="" src={SaveIcon} />}
        variant="contained"
        sx={{ fontSize: "14px" }}
        onClick={() => {
          setAction("CREATING TAX SCHEME");
        }}
        loading={action === "CREATING TAX SCHEME"}
      >
        SAVE
      </Button>
    );
  }

  if (actionAppbar === "WAITING_FOR_APPROVAL") {
    return (
      <Button
        variant="text"
        sx={{ fontSize: "14px" }}
        onClick={onClickSetPrimaryDisplay("checker")}
        startIcon={<img alt="" src={true ? HistoryIcon : ScbWaiting}></img>}
      >
        {getPrimaryDisplay("checker")}
      </Button>
    );
  }

  if (!isMaker && actionAppbar === "CHECKER_DETAIL") {
    const onClickApprove = async () => {
      try {
        setLoadingApprove(true);
        await makerCheckerService.updateMakerCheckerApprove({
          request_id: dataRequest?.transaction_id,
        });
        navigate(`/waitingforapprove`);
      } catch (error) {
        if (isValidErrorApi(error)) {
          setReturnErrorApi(error.response.data.status.description);
          setOpenError(true);
        }
      } finally {
        setLoadingApprove(false);
      }
    };

    const onClickReject = async () => {
      try {
        setLoadingReject(true);

        await makerCheckerService.updateMakerCheckerReject(
          {
            request_id: dataRequest?.transaction_id,
          },
          {},
          {
            refer_transaction_id: dataRequest?.transaction_id,
            reason: rejectReason,
            status: typeReject,
          }
        );
        navigate(`/waitingforapprove`);
      } catch (error) {
        if (isValidErrorApi(error)) {
          if (
            error.response.data.status.description !== "Invalid access token"
          ) {
            setReturnErrorApi(error.response.data.status.description);
            setOpenError(true);
          }
          setOpenReject(false);
        }
      } finally {
        setLoadingReject(false);
      }
    };

    const getLabelReject = () => {
      if (typeReject === "Rejected") {
        return "Reject Reason";
      } else if (typeReject === "Renewed") {
        return "Renew Reason";
      } else if (typeReject === "Returned") {
        return "Return Reason";
      }
    };

    const getTitleReject = () => {
      if (typeReject === "Rejected") {
        return "Enter Reject Reason";
      } else if (typeReject === "Renewed") {
        return "Enter Renew Reason";
      } else if (typeReject === "Returned") {
        return "Enter Return Reason";
      }
    };

    const isUpload = dataRequest?.transaction_type === "TRANSACTION";
    // ||
    // dataRequest?.file_upload_id > 0;

    return (
      <div className="space-x-2">
        {dataRequest?.hide_checker_action === "N" && (
          <React.Fragment>
            {dataRequest?.status === "Pending" && (
              <React.Fragment>
                <AlertModal
                  open={openError}
                  title={"Error"}
                  description={returnErrorApi}
                  action={() => setOpenError(false)}
                />
                <Button
                  variant="text"
                  sx={{ fontSize: "14px" }}
                  onClick={() => {
                    setOpenReject(true);
                    setTypeReject("Rejected");
                  }}
                  startIcon={<img alt="" src={RejectIcon}></img>}
                >
                  REJECT
                </Button>
                <PBFERejectModal
                  title={getTitleReject()}
                  label={getLabelReject()}
                  isInvalidData={rejectReason?.length < 5}
                  helperText="Reason must be at least 5 characters"
                  loading={loadingReject}
                  value={rejectReason}
                  setValue={setRejectReason}
                  open={openReject}
                  setOpen={setOpenReject}
                  onClick={onClickReject}
                  onClose={() => setOpenReject(false)}
                />
                <Button
                  variant="text"
                  sx={{ fontSize: "14px" }}
                  onClick={() => {
                    setOpenReject(true);
                    setTypeReject("Renewed");
                  }}
                  startIcon={<img alt="" src={PaperIcon}></img>}
                >
                  RENEW
                </Button>

                {!isUpload && (
                  <Button
                    variant="text"
                    sx={{ fontSize: "14px" }}
                    onClick={() => {
                      setOpenReject(true);
                      setTypeReject("Returned");
                    }}
                    startIcon={<img alt="" src={ReloadIcon}></img>}
                  >
                    RETURN
                  </Button>
                )}

                <LoadingButton
                  loading={loadingApprove}
                  onClick={onClickApprove}
                  variant="contained"
                  sx={{ fontSize: "14px" }}
                  loadingPosition="start"
                  startIcon={<img alt="" src={CheckIcon}></img>}
                >
                  APPROVE
                </LoadingButton>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  if (actionAppbar === "MAKER_DETAIL") {
    const onClickClose = async () => {
      try {
        // setLoadingRequestId(true);
        await makerCheckerService.updateMakerCheckerReject(
          {
            request_id: dataRequest?.transaction_id,
          },
          {},
          {
            status: "Closed",
            transaction_reference: closeReason,
          }
        );
        navigate(`/myrequest`);
      } catch (error) {
        if (isValidErrorApi(error)) {
          if (
            error.response.data.status.description !== "Invalid access token"
          ) {
            setReturnErrorApi(error.response.data.status.description);
            setOpenError(true);
          }
        }
        console.error("ERROR HERE C0140", error);
      } finally {
        // setLoadingRequestId(false);
      }
    };

    const onCloseReason = () => {
      setCloseReason("");
      setOpenClose(false);
    };

    return (
      <div className="space-x-2">
        <PBFECloseModal
          title={"Enter New Request ID"}
          label={"Request ID"}
          disabledConfirm={closeReason?.length === 0}
          loading={false}
          value={closeReason}
          setValue={setCloseReason}
          open={openClose}
          setOpen={setOpenClose}
          onClick={onClickClose}
          onClose={onCloseReason}
        />
        <AlertModal
          open={openError}
          title={"Error"}
          description={returnErrorApi}
          action={() => setOpenError(false)}
        />
        {dataRequest?.hide_maker_action === "N" && (
          <React.Fragment>
            {dataRequest?.status === "Renewed" && (
              <Button
                variant="contained"
                sx={{ fontSize: "14px" }}
                onClick={() => {
                  setOpenClose(true);
                }}
                startIcon={<img alt="" src={WhiteRejectIcon}></img>}
              >
                CLOSE
              </Button>
            )}
            {dataRequest?.status === "Returned" && (
              <Button
                variant="contained"
                sx={{ fontSize: "14px" }}
                startIcon={<img alt="" src={DuplicateIcon} />}
                onClick={() => {
                  dataRequest?.transaction_type === "NEWEPPBILLER"
                    ? navigate(
                        `/customer/${dataRequest?.customer_id}/new_epp_biller`
                      )
                    : dataRequest?.transaction_type === "UPDATESERVICEADDRESS"
                    ? navigate(
                        `/customer/${dataRequest?.customer_id}?transaction_id=${dataRequest?.transaction_id}`
                      )
                    : navigate(
                        `${
                          dataRequest?.account_id
                            ? `/account/${dataRequest?.account_id}?transaction_id=${dataRequest?.transaction_id}`
                            : `/customer/${dataRequest?.customer_id}?transaction_id=${dataRequest?.transaction_id}`
                        }`
                      );
                }}
              >
                DUPLICATE
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  if (actionAppbar === "EDIT_ADDRESS") {
    return (
      <Button
        variant="contained"
        loadingPosition="start"
        onClick={() => {
          onClickBeforeAction(setShowConfirm);
        }}
        startIcon={<SendIcon />}
      >
        SEND TO APPROVER
      </Button>
    );
  }

  if (actionAppbar === "NEW_EPP_BILLER") {
    return (
      <ButtonSubmitController
        render={(props) => {
          return (
            <Button
              onClick={() => {
                props?.click();
              }}
              variant="contained"
              loadingPosition="start"
              startIcon={<SendIcon />}
            >
              SEND TO APPROVER
            </Button>
          );
        }}
      />
    );
  }

  // console.log("actionAppbar", { actionAppbar, stateComponent, disabledAction });

  if (stateComponent === "customerService") {
    if (disabledAction) {
      return null;
    }

    return (
      <Button
        onClick={() => {
          onClickBeforeAction(setShowConfirm);
        }}
        disabled={disabledAction}
        variant="contained"
        loadingPosition="start"
        startIcon={<SendIcon />}
      >
        SEND TO APPROVER
      </Button>
    );
  }

  if (actionAppbar === REPORT_FEE_PATH_NAME) {
    return (
      <Button
        variant="text"
        sx={{ fontSize: "14px" }}
        onClick={() => navigate(REPORT_FEE_ROUTE + "/generateReport")}
        startIcon={<img alt="" src={PlusIcon}></img>}
      >
        GENERATE REPORT
      </Button>
    );
  }

  if (actionAppbar === REPORT_PRICE_PATH_NAME) {
    return (
      <div>
        <Button
          variant="text"
          sx={{ fontSize: "14px" }}
          onClick={() => navigate(REPORT_PRICE_ROUTE + "/generateReport")}
          startIcon={<img alt="" src={PlusIcon}></img>}
        >
          GENERATE REPORT
        </Button>
      </div>
    );
  }
  if (actionAppbar === REPORT_PRICE_TXN_SUMMARY_PATH_NAME) {
    return (
      <div>
        <Button
          variant="text"
          sx={{ fontSize: "14px" }}
          onClick={() =>
            navigate(REPORT_PRICE_TXN_SUMMARY_ROUTE + "/generateReport")
          }
          startIcon={<img alt="" src={PlusIcon}></img>}
        >
          GENERATE REPORT
        </Button>
      </div>
    );
  }
  if (actionAppbar === EMAIL_ALERT_REPORT_PATH_NAME) {
    return (
      <Button
        variant="text"
        sx={{ fontSize: "14px" }}
        onClick={() => navigate(REPORT_EMAIL_ALERT + "/generateReport")}
        startIcon={<img alt="" src={PlusIcon}></img>}
      >
        GENERATE REPORT
      </Button>
    );
  }

  if (actionAppbar === OVERDUE_REPORT_PATH_NAME) {
    return (
      <Button
        variant="text"
        sx={{ fontSize: "14px" }}
        onClick={() => navigate(REPORT_OVERDUE + "/generateReport")}
        startIcon={<img alt="" src={PlusIcon}></img>}
      >
        GENERATE REPORT
      </Button>
    );
  }

  if (actionAppbar === CUSTOMER_FEE_PROFILE_REPORT) {
    return (
      <Button
        variant="text"
        sx={{ fontSize: "14px" }}
        onClick={() =>
          navigate(REPORT_CUSTOMER_FEE_PROFILE + "/generateReport")
        }
        startIcon={<img alt="" src={PlusIcon}></img>}
      >
        GENERATE REPORT
      </Button>
    );
  }

  if (actionAppbar === REPORT_OPERATION) {
    return (
      <div>
        <Button
          variant="text"
          sx={{ fontSize: "14px" }}
          onClick={() => navigate(REPORT_OPERATION_ROUTE + "/generateReport")}
          startIcon={<img alt="" src={PlusIcon}></img>}
        >
          GENERATE REPORT
        </Button>
      </div>
    );
  }
  if (actionAppbar === REPORT_FEE_PROFILE_PATH_NAME) {
    return (
      <div>
        <Button
          variant="text"
          sx={{ fontSize: "14px" }}
          onClick={() => navigate(REPORT_FEE_PROFILE_ROUTE + "/generateReport")}
          startIcon={<img alt="" src={PlusIcon}></img>}
        >
          GENERATE REPORT
        </Button>
      </div>
    );
  }
  if (actionAppbar === "GENERATE") {
    return (
      <div className="space-x-4">
        <AlertModal
          open={openSuccess}
          title={"Generate Report"}
          description={"Generate Report Success"}
          action={() =>
            navigate(
              getReportType() === "PRICED_TRANSACTION"
                ? REPORT_PRICE_ROUTE
                : getReportType() === "PRICED_TXN_SUMMARY"
                ? REPORT_PRICE_TXN_SUMMARY_ROUTE
                : getReportType() === "EMAIL_ALERT"
                ? REPORT_EMAIL_ALERT
                : getReportType() === "OVERDUE"
                ? REPORT_OVERDUE
                : getReportType() === "ACCOUNT_STATUS"
                ? REPORT_CUSTOMER_FEE_PROFILE
                : getReportType() === "FEE_DEBIT"
                ? REPORT_FEE_ROUTE
                : getReportType() === "FEE_PROFILE"
                ? REPORT_FEE_PROFILE_ROUTE
                : REPORT_OPERATION_ROUTE
            )
          }
        />
        <Button
          variant="text"
          startIcon={<img alt="" src={ReloadIcon} />}
          sx={{ fontSize: "14px" }}
          onClick={() => setReset(!reset)}
        >
          RESET
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onClickGenerate}
          disabled={loadingGenerate}
          loading={loadingGenerate}
          startIcon={<SendIcon />}
          sx={{ fontSize: "14px" }}
        >
          GENERATE
        </LoadingButton>
        <AlertModal
          open={openError}
          title={"Error"}
          description={returnErrorApi}
          action={() => setOpenError(false)}
        />
      </div>
    );
  }

  if (actionAppbar === SETTINGS_PATH_NAME) {
    return (
      <div>
        {user?.menu?.settings === "E" && (
          <LoadingButton
            onClick={onClickEditSetting}
            variant={editSetting ? "contained" : "text"}
            loadingPosition="start"
            startIcon={
              editSetting ? (
                <img alt="" src={SaveIcon} />
              ) : (
                <img alt="" src={EditIcon} />
              )
            }
          >
            {editSetting ? "Save" : "Edit"}
          </LoadingButton>
        )}
      </div>
    );
  }

  if (actionAppbar === PROFILE_PATH_USER_GROUP_NAME) {
    return (
      <div>
        <AlertModal
          open={openError}
          title={"User Group"}
          description={returnErrorApi}
          action={() => setOpenError(false)}
        />

        {user?.menu?.admin === "E" && (
          <div>
            {groupSetting ? (
              <LoadingButton
                startIcon={<img alt="" src={SaveIcon} />}
                variant="contained"
                sx={{ fontSize: "14px" }}
                onClick={onClickEditUserGroupService}
                loading={loadingGroup}
                disabled={loadingGroup}
              >
                SAVE
              </LoadingButton>
            ) : (
              <Button
                onClick={() => setGroupSetting(true)}
                variant="text"
                sx={{ fontSize: "14px" }}
                startIcon={<img alt="" src={EditIcon} />}
              >
                EDIT
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }

  if (actionAppbar === PATH_USER_GROUP_CREATE) {
    return (
      <div>
        <AlertModal
          open={openError}
          title={"User Group"}
          description={returnErrorApi}
          action={() => setOpenError(false)}
        />
        {user?.menu?.admin === "E" && (
          <div>
            {isCreate ? (
              <Button
                startIcon={<img alt="" src={SaveIcon} />}
                variant="contained"
                sx={{ fontSize: "14px" }}
                onClick={onClickEditUserGroupService}
                loading={loadingGroup}
                disabled={loadingGroup}
              >
                SAVE
              </Button>
            ) : (
              <Button
                onClick={() => setIsCreate(true)}
                startIcon={
                  <img alt="" className="text-white" src={PlusWhiteIcon} />
                }
                variant="contained"
                sx={{ fontSize: "14px" }}
              >
                CREATE
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }

  return null;
};

function PBFEAppbar({
  titles,
  action,
  setRouteName,
  primaryDisplay,
  setPrimaryDisplay,
  dataRequest,
  setDataRequest,
  setPrevPath,
  setActionAppbar,
  actionAppbar,
  editSetting,
  setEditSetting,
  reset,
  setReset,
  dataGenerateReport,
  settingsGlobal,
  setIsCreate,
  isCreate,
  setGroupSetting,
  groupSetting,
  setDataUserGroup,
  dataUserGroup,
  dataUserProfile,
  setDataUserProfile,
  errorDesc,
  setErrorDesc,
  setAction,
  ...props
}) {
  const { openDrawer } = useContext(DrawerContext);

  const dispatch = useDispatch();
  const location = useLocation();

  const {
    loadingAction,
    onClickAction,
    isSuccess,
    confirmText,
    message,
    isError,
  } = useSelector((state) => state.actionAppbar);

  const [showConfirm, setShowConfirm] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [error, setError] = useState();
  const [templateUpload, setTemplateUpload] = useState("");
  const [validateUpload, setValidateUpload] = useState({ template: null });
  const [file, setFile] = React.useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [returnMessageApi, setReturnMessageApi] = useState("");
  const [openConfirm, setOpenConfirm] = useState("");

  useEffect(() => {
    dispatch(setStateComponent({ stateComponent: "" }));
    // setPrimaryDisplay("");
    sessionStorage.removeItem("last_condition");
  }, [location.pathname]);

  const onCloseAppbar = () => {
    setShowConfirm(false);
    dispatch(setIsError(false));
    dispatch(setIsSuccess(false));
    if (isSuccess) {
      window.location.reload();
    }
  };

  const onCloseUpload = () => {
    setOpenUpload(false);
    resetUpload();
  };

  const checkValidateExtensionFile = () => {
    const fileExt = file?.name?.split(".").pop();
    if (_.lowerCase(fileExt) === "xlsx" || _.lowerCase(fileExt) === "xls") {
      return false;
    }

    return true;
  };

  const onClickUpload = async (checkdup = true) => {
    try {
      let checkError = false;

      let tempError = {};
      if (templateUpload === "") {
        checkError = true;
        tempError = {
          ...validateUpload,
          ...tempError,
          template: "Please select a template",
        };
      }

      if (file === "") {
        checkError = true;
        tempError = {
          ...validateUpload,
          ...tempError,
          file: "Please select a file",
        };
      } else if (checkValidateExtensionFile()) {
        checkError = true;
        tempError = {
          ...validateUpload,
          ...tempError,
          file: "format file is not valid",
        };
      }

      if (checkError) {
        setValidateUpload(tempError);
        return;
      }

      setLoadingUpload(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_name", file?.name);
      formData.append("template", templateUpload);

      if (templateUpload === "TRAN" && checkdup) {
        formData.append("check_dup", "Y");
      } else {
        formData.append("check_dup", "N");
      }

      const { data } = await uploadService.postUpload(formData);

      if (data.status.description === "Success") {
        setOpenSuccess(true);
        setOpenUpload(false);
      } else {
        setError(true);
        setReturnMessageApi(data?.status?.description);
        // setOpenUpload(false);
        // setOpenError(true);
        // setReturnMessageApi(data.status.description);
      }
    } catch (error) {
      if (isValidErrorApi(error) || error.response) {
        setError(true);
        setReturnMessageApi(
          error?.response?.data?.status?.description ||
            error?.response?.data?.error
        );
      }
      console.log("uplaod error", error);
    } finally {
      setLoadingUpload(false);
      if (!checkdup) {
        setOpenConfirm(false);
      }
    }
  };

  const resetUpload = () => {
    setTemplateUpload("");
    setFile("");
    setValidateUpload({ template: null });
    setReturnMessageApi("");
    setOpenError(false);
    setError(false);
  };

  return (
    <AppBar
      sx={{
        boxShadow: 0,
        borderBottom: 1,
        borderColor: BORDER_COLOR,
        ...(!openDrawer && { width: "calc(100% - 42px)" }),
      }}
      position="fixed"
      open={openDrawer}
    >
      <Toolbar
        sx={{
          backgroundColor: "white",
        }}
      >
        <div className="w-full flex justify-between items-center">
          <PBFEBreadcrumbs
            setDataRequest={setDataRequest}
            titles={titles}
            setRouteName={setRouteName}
            setAction={setAction}
            setActionAppbar={setActionAppbar}
          />
          <AlertModal
            open={openSuccess}
            title={"Upload"}
            description={"Upload Success"}
            action={() => window.location.reload()}
          />
          <AlertModal
            open={openError}
            title={"Upload"}
            description={"Invalid Date"}
            action={() => setOpenError(false)}
          />
          <ActionAppbar
            errorDesc={errorDesc}
            setErrorDesc={setErrorDesc}
            dataGenerateReport={dataGenerateReport}
            actionAppbar={actionAppbar}
            setDataRequest={setDataRequest}
            dataRequest={dataRequest}
            setOpenUpload={setOpenUpload}
            setRouteName={setRouteName}
            action={action}
            primaryDisplay={primaryDisplay}
            setPrimaryDisplay={setPrimaryDisplay}
            titles={titles}
            setShowConfirm={setShowConfirm}
            editSetting={editSetting}
            setEditSetting={setEditSetting}
            reset={reset}
            setReset={setReset}
            settingsGlobal={settingsGlobal}
            setIsCreate={setIsCreate}
            isCreate={isCreate}
            setGroupSetting={setGroupSetting}
            groupSetting={groupSetting}
            setDataUserGroup={setDataUserGroup}
            dataUserGroup={dataUserGroup}
            dataUserProfile={dataUserProfile}
            setDataUserProfile={setDataUserProfile}
            setAction={setAction}
          />
          {/* <AlertModal
            open={openConfirm}
            title={"Upload"}
            description={"This file already uploaded."}
            action={() => {
              setOpenConfirm(false);
              setOpenUpload(true);
            }}
          /> */}
          {/* <ConfirmModal
            loading={loadingUpload}
            isTextCenter={false}
            onClose={() => {
              setOpenConfirm(false);
              setOpenUpload(true);
            }}
            open={openConfirm}
            onClick={() => onClickUpload(false)}
            text="This file already uploaded. Do you want to upload data?"
          /> */}
          <UploadModal
            errorMessage={returnMessageApi}
            validateUpload={validateUpload}
            setValidateUpload={setValidateUpload}
            loadingUpload={loadingUpload}
            error={error}
            open={openUpload}
            onClose={onCloseUpload}
            action={onClickUpload}
            setTemplateUpload={setTemplateUpload}
            templateUpload={templateUpload}
            file={file}
            setFile={setFile}
          />
          <ConfirmModal
            onClick={onClickAction}
            loading={loadingAction}
            isSuccess={isSuccess}
            isError={isError}
            message={message}
            open={showConfirm}
            onClose={onCloseAppbar}
            text={confirmText}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default PBFEAppbar;
