import makerCheckerService from "../../api/makerCheckerService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import PBFEError from "../../components/Error/alertError";
import { PBFEAccordionMain } from "../../components/Panel/Accordion/accordionMain";
import { PBFEContent } from "../../components/Elements/Text/content";
import {
  fillZeroIfEmpty,
  formateDate,
  formateDateWithTime,
  formateDateWithTimeHourMintues,
  formatEmpty,
  mappingUT10012,
} from "../../utils/util";
import React, { useEffect, useState } from "react";
import { ServiceDetail } from "../../components/Panel/Information/servicesDetail";
import { PBFEHeader } from "../../components/Elements/Text/header";
import {
  NULL_DATE,
  PRICING_FREQUENCY,
  PRICING_METHOD,
  PRICING_WITHOUT_DEBIT,
  TRANSACTION_CONSOLIDATION,
  VAT_TYPE,
  WHT_TYPE,
} from "../../constants/constant";

import {
  TableEditForNonQualifiers,
  TableEditForQualifiers,
} from "../../components/Table/editFeeTable";
import { TableCellsEditQualifiers } from "../../components/Table/tableCellQualifiers";
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  TABLE_STYLE,
  TABLE_STYLE_STANDARD_WITDH,
  TABLE_STYLE_WITHOUT_BOTTOM_BORDER,
  TABLE_STYLE_WITH_HOVER_STANDARD_WITDH,
  TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
} from "../../constants/style";
import _ from "lodash";
import { PBFEDatePicker } from "../../components/Input/datePicker";

import numeral from "numeral";
import { HookPostingAccount } from "../../functions";
import UploadMakerChecker from "../Upload/uploadMakerChecker";
import { CheckBox } from "@mui/icons-material";
import { RenderDetail } from "../OfferBundleDetail";
import { Controllers } from "../../components/radium-omnibus/src";

export const PBFETitleBefore = ({ type = "", charge }) => {
  return (
    <div className="flex justify-between ">
      <div
        className={`grid w-button text-white px-3 py-2  mb-3  bg-gray-400 my-4 `}
      >
        BEFORE
      </div>
      {type === "update" && charge?.price_without_post === "Y" && (
        <div className="mr-15px text-textprimary text-value">
          {PRICING_WITHOUT_DEBIT}
        </div>
      )}
    </div>
  );
};

export const PBFETitleAfter = ({ type = "", charge }) => {
  return (
    <div className="flex justify-between ">
      <div
        className={`grid w-button text-white px-3 py-2  mb-3 bg-purple-600 my-4 `}
      >
        AFTER
      </div>
      {type === "update" && charge?.price_without_post === "Y" && (
        <div className="mr-15px text-textprimary text-value">
          {PRICING_WITHOUT_DEBIT}
        </div>
      )}
    </div>
  );
};

export const PBFESectionReason = ({ data }) => {
  return (
    <div className="grid grid-cols-1 gap-y-6 gap-x-2 px-12 pb-xxs pt-xxs my-6">
      <PBFEContent label={`${data.status} Reason`} value={data.reason} />
    </div>
  );
};

export const PBFEGeneralInfoChecker = ({ data }) => {
  const isPending = data?.status === "Pending";
  return (
    <div className="grid grid-cols-4 gap-y-6 gap-x-2 px-12 pb-xxs pt-xxs my-6">
      <PBFEContent
        label="Request ID"
        value={formatEmpty(data?.transaction_reference)}
      />
      <div className="col-span-2">
        <PBFEContent
          label="Request Type"
          value={formatEmpty(data.description)}
        />
      </div>
      <PBFEContent label="Status" value={formatEmpty(data.status)} />
      <PBFEContent label="Maker" value={formatEmpty(data?.maker)} />
      <PBFEContent
        label="Request Date Time"
        value={formateDateWithTimeHourMintues(data.create_datetime)}
      />
      {!isPending && (
        <PBFEContent label="Checker" value={formatEmpty(data?.approval)} />
      )}
      {!isPending && (
        <PBFEContent
          label={`${data.status} Date Time`}
          value={` ${formatEmpty(
            formateDateWithTimeHourMintues(data.approval_datetime)
          )}`}
        />
      )}
    </div>
  );
};

export const PBFEGeneralInfoCustomerAccount = ({ data }) => {
  return (
    <div className="grid grid-cols-2 gap-y-6 gap-x-2 px-12 pb-xxs pt-xxs my-6">
      {data?.data?.display_obj?.account_name && (
        <React.Fragment>
          <PBFEContent
            label="Account Name"
            value={formatEmpty(data?.data?.display_obj?.account_name)}
          />
          <PBFEContent
            label="Account No."
            value={formatEmpty(data?.data?.display_obj?.account_id)}
          />
        </React.Fragment>
      )}
      {/* {data?.data.display_obj?.offer_code && (
        <React.Fragment>
          <PBFEContent
            label="Offer Name"
            value={formatEmpty(data?.data.display_obj?.offer_name)}
          />
          <PBFEContent
            label="Offer No."
            value={formatEmpty(data?.data.display_obj?.offer_code)}
          />
        </React.Fragment>
      )} */}
      {data?.data?.display_obj?.customer_id && (
        <React.Fragment>
          <PBFEContent
            label="Customer Name"
            value={formatEmpty(data?.data?.display_obj?.customer_name)}
          />
          <PBFEContent
            label="Customer ID"
            value={formatEmpty(data?.data?.display_obj?.customer_id)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export const PFFERenderUpdateDebitProfile = ({
  before_debit = [],
  after_debit = [],
}) => {
  const filter_delete_after = after_debit?.filter((f) => {
    return f?.action !== "DELETE";
  });

  if (filter_delete_after?.length > 0 && before_debit?.length === 0) {
    return (
      <PBFEAccordionMain
        pl=""
        label="Debit Profile"
        detail={
          <React.Fragment>
            {/* <div className="">
              <PBFETitleBefore />
            </div>
            <div className="">
              <PBFETitleAfter />
            </div> */}
            <PBFEDebitProfileTable rows={filter_delete_after} />
          </React.Fragment>
        }
      />
    );
  } else if (filter_delete_after?.length > 0 && before_debit?.length > 0) {
    return (
      <PBFEAccordionMain
        pl=""
        label="Debit Profile"
        detail={
          <React.Fragment>
            <div className="">
              <PBFETitleBefore />
            </div>
            <PBFEDebitProfileTable rows={before_debit} />
            <div className="">
              <PBFETitleAfter />
            </div>
            <PBFEDebitProfileTable rows={after_debit} />
          </React.Fragment>
        }
      />
    );
  } else if (before_debit?.length > 0 && filter_delete_after?.length === 0) {
    return (
      <PBFEAccordionMain
        pl=""
        label="Debit Profile"
        detail={
          <React.Fragment>
            <div className="">
              <PBFETitleBefore />
            </div>
            <PBFEDebitProfileTable rows={before_debit} />
            <div>
              <PBFETitleAfter />
            </div>
          </React.Fragment>
        }
      />
    );
  } else {
    return "";
  }
};

export const PBFERenderNewService = ({ data }) => {
  const [dataGroup, setDataGroup] = useState([]);
  useEffect(() => {
    if (data) {
      if (data?.data?.display_obj?.service?.length > 0) {
        const newData = fillZeroIfEmpty(
          data?.data?.display_obj?.service[0]?.charge_group
        );
        setDataGroup(newData);
      }
    }
  }, [data]);
  if (dataGroup.length > 0) {
    return (
      <div className="w-full overflow-auto pt-xxl">
        <div className="h-10px bg-gray-100"></div>
        <PBFEGeneralInfoChecker data={data} />
        <div className="h-10px bg-gray-100"></div>
        {data.reason && (
          <React.Fragment>
            <PBFESectionReason data={data} />
            <div className="h-10px bg-gray-100"></div>
          </React.Fragment>
        )}
        <PBFEGeneralInfoCustomerAccount data={data} />
        <div className="h-10px bg-gray-100"></div>

        <PBFEAccordionMain
          label="Service"
          detail={
            <React.Fragment>
              <PBFEAccordionMain
                pl="ml-20px"
                customHeader={
                  <PBFEHeader
                    p=""
                    label={
                      data?.data?.display_obj?.service?.length > 0
                        ? `${data?.data?.display_obj?.service[0].service_name} - ${data?.data?.display_obj?.service[0].service_code}`
                        : "ERROR"
                    }
                    purple={true}
                    editable={false}
                  />
                }
                detail={
                  <React.Fragment>
                    {data?.data?.display_obj?.fcy_flag === "Y" && (
                      <div className="w-[25%] p-4">
                        <PBFEContent
                          label="Pricing & Debit Currency"
                          value={data?.data?.display_obj?.ccy}
                        />
                      </div>
                    )}
                    <PFFERenderUpdateDebitProfile
                      after_debit={
                        data?.data?.display_obj?.service[0]?.posting_account
                      }
                    />
                    {dataGroup?.length > 0 &&
                      dataGroup?.map((group, index) => {
                        if (!group?.fee_charge?.length) return;
                        // NEW
                        if (group?.charge_code === "UT10012") {
                          group.fee_charge = mappingUT10012(group.fee_charge);
                        }

                        return (
                          <ServiceDetail
                            key={`service-detail-${index}`}
                            primaryIndex={index}
                            primaryField="fee_charge"
                            origin={data}
                            data={group.fee_charge}
                            charge_code={group.charge_code}
                            charge_name={group.charge_name}
                            isGroup={true}
                            lenghGroup={dataGroup.length}
                            isEdit={false}
                          />
                        );
                      })}
                  </React.Fragment>
                }
              />
            </React.Fragment>
          }
        />
        <div className="h-10px bg-gray-100"></div>
        <div className="px-12 pb-xxs pt-xxs my-6">
          <div className="text-primarydark font-bold text-14px mb-2">
            REMARK
          </div>
          <div className="p-4 bg-gray-100 text-14px break-words">
            {data?.remark}
          </div>
        </div>
      </div>
    );
  } else {
    return "ERROR";
  }
};

export const PBFERenderHeaderChargeFromService = ({
  charge,
  isBundle = false,
}) => {
  const isSubscription = charge?.fee_charge?.pricing_method === "SUBSCRIPTION";

  return (
    <React.Fragment>
      <div className="flex justify-between"></div>
      <div className="grid grid-cols-5 gap-40px my-20px ">
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label="Pricing Method"
          value={PRICING_METHOD[charge?.fee_charge?.pricing_method]}
        />
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label={`Pricing Frequency`}
          value={PRICING_FREQUENCY[charge.fee_charge?.pricing_frequency]}
        />
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label={`Transaction Consolidation`}
          value={
            TRANSACTION_CONSOLIDATION[
              charge?.fee_charge?.transaction_consolidation
            ]
          }
        />

        {/* {!isSubscription ? ( */}
        {!isBundle && (
          <PBFEContent
            w=" w-20 "
            mode="flex w-full"
            label={`VAT`}
            value={VAT_TYPE[charge?.fee_charge?.vat_type]}
          />
        )}
        {/* ) : (
          <div></div>
        )} */}
        {!isBundle && (
          <PBFEContent
            w=" w-20 "
            mode="flex w-full"
            label={`WHT`}
            value={WHT_TYPE[charge?.fee_charge?.wht_type]}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export const PBFERenderHeaderChargeFromServicePosting = ({ charge }) => {
  const isSubscription = charge?.fee_charge?.pricing_method === "SUBSCRIPTION";

  return (
    <React.Fragment>
      <div className="flex justify-between">
        {/* <div
          className={`  w-button  text-white px-3 py-2 inline-block mb-3 ${
            charge.type === "before" ? "bg-gray-400" : "bg-purple-600"
          }`}
        >
          {_.upperCase(charge.type)}
        </div> */}
        {/* {charge?.fee_charge?.price_without_post === "Y" && (
          <div className="mr-15px text-textprimary text-value ml-auto">
            {PRICING_WITHOUT_DEBIT}
          </div>
        )} */}
      </div>
      <div className="grid grid-cols-5 gap-40px my-20px">
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label="Posting Type"
          value={charge.fee_charge?.posting_type}
        />
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label={`Posting Frequency`}
          value={charge.fee_charge?.posting_frequency}
        />
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label={`Posting Consolidation`}
          value={charge?.fee_charge?.posting_consolidation}
        />

        {/* {!isSubscription ? ( */}
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label={`Monthly Pricing Day`}
          value={charge?.fee_charge?.monthly_pricing_day}
        />
        {/* ) : (
          <div></div>
        )} */}
        <PBFEContent
          w=" w-20 "
          mode="flex w-full"
          label={`Qualifiers`}
          value={
            Object.keys(charge?.fee_charge?.qualifiers || {})?.length > 0 ||
            false
              ? "Yes"
              : "No"
          }
        />
      </div>
    </React.Fragment>
  );
};

export const PBFERenderFromTo = ({ charge }) => {
  const isSubscription = charge?.fee_charge?.pricing_method === "SUBSCRIPTION";
  return (
    <div className="grid grid-cols-5 gap-40px  my-20px px-xl">
      <PBFEContent
        mode="flex w-full"
        label={"Effective From"}
        value={formateDate(charge?.fee_charge?.from_date)}
      />

      <PBFEContent
        mode="flex w-full"
        label={"To"}
        value={formateDate(charge?.fee_charge?.to_date)}
      />
      {isSubscription && (
        <PBFEContent
          mode="flex w-full"
          label={"Fixed Amount"}
          value={numeral(
            charge?.fee_charge?.fee_value?.value[0]?.fix_amount
          ).format("0,0.00")}
        />
      )}
    </div>
  );
};

export const PBFEDebitProfileTable = ({ rows }) => {
  return (
    <React.Fragment>
      <div className="border-t border-b">
        <TableContainer>
          <Table sx={TABLE_STYLE_STANDARD_WITDH} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align={"left"} padding={"normal"}>
                  Acount No.
                </TableCell>
                <TableCell align={"left"} padding={"normal"}>
                  Acount Name
                </TableCell>
                <TableCell align={"left"} padding={"normal"}>
                  Account Status
                </TableCell>
                <TableCell align={"left"} padding={"normal"}>
                  From
                </TableCell>
                <TableCell align={"left"} padding={"normal"}>
                  To
                </TableCell>
              </TableRow>
            </TableHead>
            {rows?.map((row, index) => {
              return (
                <TableBody key={`table${index}`}>
                  <TableRow
                    id="table-row"
                    key={index}
                    sx={
                      (TABLE_STYLE_WITHOUT_BOTTOM_BORDER,
                      { backgroundColor: "#00000014" })
                    }
                  >
                    <TableCell id="table-cell-id" align="left">
                      {HookPostingAccount(`${row.posting_account_id}`)}
                    </TableCell>
                    <TableCell id="table-cell-name" align="left">
                      {row.posting_account_name}
                    </TableCell>
                    <TableCell id="table-cell-status" align="left">
                      {row.status}
                    </TableCell>
                    <TableCell id="table-cell-from" align="left">
                      {row.from_date ? formateDate(row.from_date) : "-"}
                    </TableCell>
                    <TableCell id="table-cell-to" align="left">
                      {row?.to_date === NULL_DATE
                        ? "-"
                        : formateDate(row.to_date)}
                    </TableCell>
                    <TableCell
                      sx={{ width: "25px" }}
                      id="table-cell-to"
                      align="left"
                    ></TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export const PBFERenderUpdateService = ({ data }) => {
  const [service, setService] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if (data) {
      const display_obj = data?.data?.display_obj;
      const update_obj = data?.data?.update_obj;

      const charge_group_raw = _.cloneDeep(update_obj?.fee_charge)
        .concat(display_obj?.after_charge.filter((after) => !after.action))
        ?.map((charge) => {
          let tempBefore = null;
          let tempAfter = null;
          const find_before_charge_index = _.cloneDeep(
            display_obj?.before_charge
          )?.findIndex((before) => {
            return before.charge_code === charge.charge_code;
          });

          const find_after_charge_index = _.cloneDeep(
            display_obj?.after_charge
          )?.findIndex((after) => {
            return after.charge_code === charge.charge_code;
          });

          if (find_before_charge_index !== -1) {
            tempBefore = display_obj?.before_charge[find_before_charge_index];
            display_obj?.before_charge.splice(find_before_charge_index, 1);
          }

          if (find_after_charge_index !== -1) {
            tempAfter = display_obj?.after_charge[find_after_charge_index];
            display_obj?.after_charge.splice(find_after_charge_index, 1);
          }

          return {
            charge_code: charge?.charge_code,
            charge_name: charge?.charge_name,
            before_charge: {
              type: "before",
              fee_charge: tempBefore || null,
            },
            after_charge: {
              type: "after",
              fee_charge: tempAfter || null,
            },
          };
        });

      const charge_group = fillZeroIfEmpty(charge_group_raw, true);

      const new_service = {
        service_name: display_obj.service_name,
        service_code: display_obj.service_code,
        charge_group,
        before_debit: display_obj.before_debit,
        after_debit: display_obj.after_debit,
      };

      setService(new_service);
    }
  }, [data]);

  useEffect(() => {
    setAllData(getChargeBeforeAfter(service?.charge_group));
  }, [service]);

  const getChargeBeforeAfter = (array = [], before_current = []) => {
    if (!array) return [];

    // console.log("before_current", before_current);

    const output = array.reduce(function (previous, current) {
      const occurs = previous.reduce(function (
        previous_item,
        current_item,
        current_index
      ) {
        return current_item.charge_code === current.charge_code
          ? current_index
          : previous_item;
      },
      -1);

      if (occurs >= 0) {
        previous[occurs].after_charge = previous[occurs].after_charge.concat(
          current.after_charge
        );
        previous[occurs].before_charge = previous[occurs].before_charge.concat(
          current.before_charge
        );
      } else {
        const obj = {
          charge_code: current.charge_code,
          charge_name: current.charge_name,
          after_charge: [current.after_charge],
          before_charge: [current.before_charge],
        };
        previous = previous.concat([obj]);
      }

      return previous;
    }, []);

    output.forEach((o, i) => {
      const foundCurrentIndex = before_current?.findIndex(
        (current) => current.charge_code === o.charge_code
      );

      if (foundCurrentIndex >= 0) {
        o.before_charge.push({
          type: "before",
          fee_charge: { ...before_current[foundCurrentIndex], action: "EDIT" },
        });
        o.after_charge.push({
          type: "after",
          fee_charge: { ...before_current[foundCurrentIndex], action: "EDIT" },
        });
        // output.before_charge[i] = [
        //   ...output.before_charge[i],
        //   before_current[foundCurrentIndex],
        // ];
        before_current.splice(foundCurrentIndex, 1);
      }
    });

    // console.log("findIndexBefore", findIndexBefore);

    // console.log("before_current", before_current);

    // before_current.forEach((item) => {
    //   output.before_charge.
    // }

    return output;

    // let output = [];

    // array.forEach(function (item) {
    //   console.log("item", item);
    //   const existing = output.filter(function (v) {
    //     return v?.charge_code == item?.charge_code;
    //   });
    //   if (existing?.length > 0) {
    //     const existingIndex = output?.indexOf(existing[0]);

    //     console.log(
    //       "output[existingIndex]",
    //       output[existingIndex]?.after_charge?.fee_charge
    //     );
    //     output[existingIndex]?.after_charge?.fee_charge.push(
    //       item?.after_charge?.fee_charge
    //     );
    //     // output[existingIndex]?.after_charge?.fee_charge = output[
    //     //   existingIndex
    //     // ]?.after_charge?.fee_charge?.concat(item?.after_charge?.fee_charge);
    //   } else {
    //     item.after_charge.fee_charge = [item.after_charge.fee_charge];
    //     item.before_charge.fee_charge = [item.before_charge.fee_charge];
    //     output.push(item);
    //   }
    // });

    // console.log("output : ", output);

    // return [];

    // let fee_charge_after = [];
    // let fee_charge_before = [];

    // const after_charge = array?.map((o) => {
    //   return o.after_charge;
    // });

    // const before_charge = array?.map((o) => {
    //   return o.before_charge;
    // });

    // after_charge.forEach((d) => {
    //   fee_charge_after.push(d.fee_charge);
    // });

    // before_charge.forEach((d) => {
    //   fee_charge_before.push(d.fee_charge);
    // });

    // return { before: fee_charge_before, after: fee_charge_after };

    // temp.reduce((rows, row) => {
    //   // console.log("charge_code", after_charge);

    //   // if (itemIndex === -1) {
    //   //   console.log("IN1");
    //   //   rows.push({
    //   //     charge_code: row?.charge_code,
    //   //     charge_name: row?.charge_name,
    //   //     before_charge: [row?.before_charge],
    //   //     after_charge: [row?.after_charge],
    //   //   });
    //   // } else {
    //   //   console.log("IN2");
    //   // }

    //   // if(){

    //   // }

    //   // const itemIndex = rows?.findIndex((inf) => {
    //   //   console.log("rowsinFind", rows);
    //   //   return inf.charge_code === charge_code;
    //   // });

    //   // if (itemIndex < 0) {
    //   //   console.log("IN 1");
    //   //   rows.push(row);
    //   // } else {
    //   //   console.log("IN 2");

    //   //   // temp.push("2");
    //   // }

    //   console.log("rows", rows);
    //   console.log("ROW :  ", row);
    //   // console.log("itemIndex", itemIndex);
    //   // console.log("new_after_charge", new_after_charge);
    //   return [];
    // }, []);

    // temp.forEach((d) => {
    //   console.log("D: ", d);
    // const findIndex = temp?.findIndex(
    //   (item) => item?.charge_code === d?.charge_code
    // );

    // console.log("findIndex", findIndex);

    // if (findIndex >= 0) {
    //   new_after_charge.push(temp[findIndex]?.after_charge?.fee_charge);
    // } else {
    //   return [];
    // }
  };

  // console.log("service?.charge_group", service?.charge_group);

  const mix_charge_with_before_and_after = allData;

  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <PBFEGeneralInfoCustomerAccount data={data} />
      <div className="h-10px bg-gray-100"></div>
      <PBFEAccordionMain
        label="Service"
        detail={
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <PBFEHeader
                p=""
                label={`${service.service_name} - ${service.service_code}`}
                purple={true}
                editable={false}
              />
            }
            detail={
              <React.Fragment>
                <PFFERenderUpdateDebitProfile
                  before_debit={service.before_debit}
                  after_debit={service.after_debit}
                />

                {mix_charge_with_before_and_after?.map((row, index) => {
                  return (
                    <PBFEAccordionMain
                      key={`group-${index}`}
                      pl="ml-20px"
                      label={`${row.charge_code} - ${row.charge_name}`}
                      // showLabel={}
                      detail={
                        <React.Fragment>
                          {/* BEFOREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE */}

                          <PBFETitleBefore
                            type="update"
                            charge={row?.before_charge[0]}
                          />

                          {row?.before_charge
                            ?.filter((o) => {
                              return o.fee_charge?.action !== "NEW";
                            })
                            ?.sort((a, b) => {
                              return (
                                new Date(a?.fee_charge?.from_date) -
                                new Date(b?.fee_charge?.from_date)
                              );
                            })
                            ?.map((charge, index) => {
                              if (charge?.fee_charge) {
                                const isSubscription =
                                  charge?.fee_charge?.pricing_method ===
                                  "SUBSCRIPTION";

                                if (
                                  charge?.fee_charge.charge_code === "UT10012"
                                ) {
                                  charge.fee_charge = mappingUT10012([
                                    charge.fee_charge,
                                  ])[0];
                                }

                                return (
                                  <React.Fragment>
                                    {index === 0 && (
                                      <PBFERenderHeaderChargeFromService
                                        charge={charge}
                                      />
                                    )}
                                    <PBFERenderFromTo charge={charge} />

                                    {!isSubscription && (
                                      <div
                                        id={row?.charge_code}
                                        className="border-t border-b pb-fourteen"
                                      >
                                        <TableContainer>
                                          <Table sx={TABLE_STYLE}>
                                            {/* {row?.before_charge?.map((charge) => {
                                            if (charge?.fee_charge) {
                                              return ( */}
                                            {/* <React.Fragment> */}
                                            <TableCellsEditQualifiers
                                              isEdit={false}
                                              data={charge?.fee_charge}
                                              withPricingMethod={false}
                                            />
                                            <TableBody
                                              sx={{ marginTop: "16px" }}
                                            >
                                              {charge?.fee_charge?.fee_value
                                                ?.conditions?.length > 0 ? (
                                                <TableEditForQualifiers
                                                  data={charge?.fee_charge}
                                                  isEdit={false}
                                                />
                                              ) : (
                                                <TableEditForNonQualifiers
                                                  isEdit={false}
                                                  data={charge.fee_charge}
                                                />
                                              )}
                                            </TableBody>
                                            {/* </React.Fragment>
                                              );
                                            } else {
                                              return null;
                                            }
                                          })} */}
                                          </Table>
                                        </TableContainer>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                return null;
                              }
                            })}
                          {/* AFTERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR */}

                          <PBFETitleAfter
                            type="update"
                            charge={row?.after_charge[0]}
                          />

                          {row?.after_charge
                            ?.filter((o) => {
                              return o.fee_charge?.action !== "DELETE";
                            })
                            ?.sort((a, b) => {
                              return (
                                new Date(a?.fee_charge?.from_date) -
                                new Date(b?.fee_charge?.from_date)
                              );
                            })
                            ?.map((charge, index) => {
                              if (charge?.fee_charge) {
                                const isSubscription =
                                  charge?.fee_charge?.pricing_method ===
                                  "SUBSCRIPTION";

                                console.log("charge", charge);

                                if (
                                  charge?.fee_charge.charge_code === "UT10012"
                                ) {
                                  charge.fee_charge = mappingUT10012([
                                    charge.fee_charge,
                                  ])[0];
                                }

                                return (
                                  <React.Fragment>
                                    {index === 0 && (
                                      <PBFERenderHeaderChargeFromService
                                        charge={charge}
                                      />
                                    )}
                                    <PBFERenderFromTo charge={charge} />

                                    {!isSubscription && (
                                      <div
                                        id={row?.charge_code}
                                        className="border-t border-b pb-fourteen"
                                      >
                                        <TableContainer>
                                          <Table sx={TABLE_STYLE}>
                                            {/* {row?.after_charge?.map((charge) => {
                                            if (charge?.fee_charge) {
                                              return ( */}
                                            {/* <React.Fragment> */}
                                            <TableCellsEditQualifiers
                                              isEdit={false}
                                              data={charge?.fee_charge}
                                              withPricingMethod={false}
                                            />
                                            <TableBody
                                              sx={{ marginTop: "16px" }}
                                            >
                                              {charge?.fee_charge?.fee_value
                                                ?.conditions?.length > 0 ? (
                                                <TableEditForQualifiers
                                                  data={charge?.fee_charge}
                                                  isEdit={false}
                                                />
                                              ) : (
                                                <TableEditForNonQualifiers
                                                  isEdit={false}
                                                  data={charge?.fee_charge}
                                                />
                                              )}
                                            </TableBody>
                                            {/* </React.Fragment>
                                              );
                                            } else {
                                              return null;
                                            }
                                          })} */}
                                          </Table>
                                        </TableContainer>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </React.Fragment>
                      }
                    />
                  );
                })}
              </React.Fragment>
            }
          />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div>
    </div>
  );
};

export const PBFERenderUpdateServiceInfo = ({ data }) => {
  const Preview = ({ content }) => {
    return (
      <>
        <PBFEContent
          label="Full Thai Name"
          value={formatEmpty(content?.customer_full_name_thai)}
        />
        <PBFEContent
          label="Tax Branch No."
          value={formatEmpty(content?.customer_branch_no)}
        />

        <div className="text-textscb text-value mt-20px">
          Registered Address
        </div>
        <div className="text-value mt-5px mb-20px font-bold  text-textprimary">
          {formatEmpty(content?.reg_addr_line1, "") +
            " " +
            formatEmpty(content?.reg_addr_line2, "") +
            " " +
            formatEmpty(content?.reg_addr_line3, "") +
            " " +
            formatEmpty(content?.reg_zip_code, "")}
        </div>
        <div className="text-textscb text-value flex flex-row mt-20px">
          <div>Contact Address</div>
          <div className=" ml-40px -mt-10px cursor-text">
            <FormControlLabel
              control={
                <Checkbox
                  checked={content.different_contact_addr_flag === "N"}
                />
              }
              label={"Same As Registered Address"}
            />
          </div>
        </div>
        {content.different_contact_addr_flag === "Y" && (
          <div className="text-value mt-5px font-bold  text-textprimary">
            {formatEmpty(content?.contact_addr_line1, "") +
              " " +
              formatEmpty(content?.contact_addr_line2, "") +
              " " +
              formatEmpty(content?.contact_addr_line3, "") +
              " " +
              formatEmpty(content?.contact_zip_code, "")}
          </div>
        )}
        <div className="mt-20px text-textscb text-value">Email</div>
        <div className="text-textprimary text-value flex flex-row mt-5px mb-20px">
          <div className="font-bold">{content?.email}</div>
          <div className=" ml-40px -mt-10px cursor-text">
            <FormControlLabel
              control={<Checkbox checked={content.email_alert_flag === "Y"} />}
              label={"Email Alert"}
            />
          </div>
        </div>
      </>
    );
  };

  const before = data?.data?.display_obj?.before;
  const after = data?.data?.display_obj?.after;

  // console.log("data ", data);

  return (
    <div className="w-full overflow-auto pt-xxl ">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <div className="grid grid-cols-2 gap-y-6 gap-x-2 px-12 pb-xxs pt-xxs my-6">
        <PBFEContent
          label="Customer Name"
          value={formatEmpty(data?.customer_name)}
        />
        <PBFEContent
          label="Customer No."
          value={formatEmpty(data?.customer_id)}
        />
        <PBFEContent
          label="Account No."
          value={formatEmpty(data?.account_id)}
        />
        <PBFEContent label="Service" value={formatEmpty(data?.reference1)} />
      </div>
      <div className="h-10px bg-gray-100"></div>
      <div className="px-12 w-full">
        <PBFETitleBefore type="address" />
        <Preview content={before} />
        <PBFETitleAfter type="address" />
        <Preview content={after} />
      </div>
      <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div>
    </div>
  );
};
// display obj offer code / offer name  / account id / account name / customer name / customer id / from date / to date
export const PBFERenderNewOffer = ({ data }) => {
  const [dataOffer, setDataOffer] = useState([]);

  useEffect(() => {
    if (data) {
      setDataOffer(
        data?.data?.display_obj?.raw_data?.offer_fee_service ||
          data?.data?.display_obj?.offer_fee_service
      );
    }
  }, [data]);

  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <PBFEGeneralInfoCustomerAccount data={data} />
      <div className="h-10px bg-gray-100"></div>

      <PBFEAccordionMain
        label="Offer"
        detail={
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <PBFEHeader
                p=""
                label={`${data?.data?.display_obj?.offer_name} - ${data?.data?.display_obj?.offer_code}`}
                purple={true}
                editable={false}
                isOffer={true}
              />
            }
            detail={
              <React.Fragment>
                <div className="grid grid-cols-5 gap-40px my-25px">
                  <PBFEDatePicker
                    keyName="from_date"
                    data={data?.data?.display_obj}
                    label={"Effective From"}
                    isEdit={false}
                  />
                  <PBFEDatePicker
                    keyName="to_date"
                    data={data?.data?.display_obj}
                    label="To"
                    isEdit={false}
                  />
                </div>
                <PFFERenderUpdateDebitProfile
                  after_debit={data?.data?.display_obj?.posting_account}
                />
                {dataOffer?.map((info) => {
                  return (
                    <PBFEAccordionMain
                      pl="ml-20px"
                      customHeader={
                        <PBFEHeader
                          p=""
                          label={info.service_name + " - " + info.service_code}
                          purple={true}
                          editable={false}
                          hideButton={true}
                        />
                      }
                      detail={
                        <ServiceDetail
                          key={`${info.service_code}-${info.service_name}`}
                          isOffer={true}
                          primaryField="fee_charge"
                          origin={info}
                          data={info.fee_charge}
                          account_table={[]}
                          isEdit={false}
                        />
                      }
                    />
                  );
                })}
              </React.Fragment>
            }
          />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div>
    </div>
  );
};

export const PBFERenderEditFromToOffer = ({ before_date, after_date }) => {
  if (before_date?.to_date && after_date?.to_date) {
    return (
      <div>
        <PBFETitleBefore />
        <div className="grid grid-cols-5 gap-40px my-25px">
          <PBFEDatePicker
            keyName="from_date"
            data={before_date}
            label={"Effective From"}
            isEdit={false}
          />
          <PBFEDatePicker
            keyName="to_date"
            data={before_date}
            label="To"
            isEdit={false}
          />
        </div>
        <PBFETitleAfter />
        <div className="grid grid-cols-5 gap-40px my-25px">
          <PBFEDatePicker
            keyName="from_date"
            data={after_date}
            label={"Effective From"}
            isEdit={false}
          />
          <PBFEDatePicker
            keyName="to_date"
            data={after_date}
            label="To"
            isEdit={false}
          />
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const PBFERenderUpdateOffer = ({ data }) => {
  const [beforeDebit, setBeforeDebit] = useState([]);
  const [afterDebit, setAfterDebit] = useState([]);

  useEffect(() => {
    const display_obj = data?.data?.display_obj;
    setAfterDebit(display_obj.after_debit);
    setBeforeDebit(display_obj?.before_debit);
  }, [data]);

  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <PBFEGeneralInfoCustomerAccount data={data} />
      <div className="h-10px bg-gray-100"></div>

      <PBFEAccordionMain
        label="Offer"
        detail={
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <PBFEHeader
                p=""
                label={`${data?.data?.display_obj?.offer_name} - ${data?.data?.display_obj?.offer_code}`}
                purple={true}
                editable={false}
                isOffer={true}
              />
            }
            detail={
              <React.Fragment>
                <PBFERenderEditFromToOffer
                  before_date={data?.data?.display_obj?.before_date}
                  after_date={data?.data?.display_obj?.after_date}
                />
                <PFFERenderUpdateDebitProfile
                  after_debit={afterDebit}
                  before_debit={beforeDebit}
                />
                {data?.data?.display_obj?.raw_data?.service?.map(
                  (info, index) => {
                    return (
                      <PBFEAccordionMain
                        pl="ml-20px"
                        customHeader={
                          <PBFEHeader
                            p=""
                            label={
                              info.service_name + " - " + info.service_code
                            }
                            purple={true}
                            editable={false}
                            hideButton={true}
                          />
                        }
                        detail={
                          <ServiceDetail
                            key={`${info.service_code}-${index}`}
                            isOffer={true}
                            primaryIndex={index}
                            primaryField="fee_charge"
                            origin={info}
                            data={info.fee_charge}
                            isEdit={false}
                          />
                        }
                      />
                    );
                  }
                )}
              </React.Fragment>
            }
          />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div>
    </div>
  );
};

export const PBFERenderUpdateBundleOffer = ({ data }) => {
  const [beforeDebit, setBeforeDebit] = useState([]);
  const [afterDebit, setAfterDebit] = useState([]);

  useEffect(() => {
    const display_obj = data?.data?.display_obj;
    setAfterDebit(display_obj.after_debit);
    setBeforeDebit(display_obj?.before_debit);
  }, [data]);

  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <PBFEGeneralInfoCustomerAccount data={data} />
      <div className="h-10px bg-gray-100"></div>

      <PBFEAccordionMain
        label="Bundled Offer"
        detail={
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <PBFEHeader
                p=""
                label={`${data?.data?.display_obj?.offer_name} - ${data?.data?.display_obj?.offer_code}`}
                purple={true}
                editable={false}
                isOffer={true}
              />
            }
            detail={
              <React.Fragment>
                <PBFERenderEditFromToOffer
                  before_date={data?.data?.display_obj?.before_date}
                  after_date={data?.data?.display_obj?.after_date}
                />
                <PFFERenderUpdateDebitProfile
                  after_debit={afterDebit}
                  before_debit={beforeDebit}
                />
                <RenderDetail data={data?.data?.display_obj?.raw_data} />
              </React.Fragment>
            }
          />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div>
    </div>
  );
};

export const PBFERenderNewBundleOffer = ({ data }) => {
  // const [dataBundleOffer, setDataBundleOffer] = useState([]);

  // useEffect(() => {
  //   if (!data) return;
  //   setDataOffer(
  //     data?.data?.display_obj?.raw_data?.offer_fee_service ||
  //       data?.data?.display_obj?.offer_fee_service
  //   );
  // }, [data]);

  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data?.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <PBFEGeneralInfoCustomerAccount data={data} />
      <div className="h-10px bg-gray-100"></div>

      <PBFEAccordionMain
        label="Offer"
        detail={
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <PBFEHeader
                p=""
                label={`${data?.data?.display_obj?.offer_name} - ${data?.data?.display_obj?.offer_code}`}
                purple={true}
                editable={false}
                isOffer={true}
              />
            }
            detail={
              <React.Fragment>
                <div className="grid grid-cols-5 gap-40px my-25px">
                  <PBFEDatePicker
                    keyName="from_date"
                    data={data?.data?.display_obj}
                    label={"Effective From"}
                    isEdit={false}
                  />
                  <PBFEDatePicker
                    keyName="to_date"
                    data={data?.data?.display_obj}
                    label="To"
                    isEdit={false}
                  />
                </div>
                <PFFERenderUpdateDebitProfile
                  after_debit={data?.data?.display_obj?.posting_account}
                />
                <RenderDetail data={data?.data?.display_obj?.raw_data} />
                {/* {dataOffer?.map((info) => {
                  return (
                    <PBFEAccordionMain
                      pl="ml-20px"
                      customHeader={
                        <PBFEHeader
                          p=""
                          label={info.service_name + " - " + info.service_code}
                          purple={true}
                          editable={false}
                          hideButton={true}
                        />
                      }
                      detail={
                        <ServiceDetail
                          key={`${info.service_code}-${info.service_name}`}
                          isOffer={true}
                          primaryField="fee_charge"
                          origin={info}
                          data={info.fee_charge}
                          account_table={[]}
                          isEdit={false}
                        />
                      }
                    />
                  );
                })} */}
              </React.Fragment>
            }
          />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div>
    </div>
  );
};

export const PBFERenderNewEPPBiller = ({ data }) => {
  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data?.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <div className="grid grid-cols-2 w-full px-xl my-20px">
        <PBFEContent label="Customer Name" value={data?.customer_name} />
        <PBFEContent label="Customer ID" value={data?.customer_id} />
      </div>
      <div className="h-10px bg-gray-100"></div>

      <PBFEAccordionMain
        label="ePP Biller"
        detail={
          <div className="grid grid-cols-4  gap-x-10 gap-y-2 pt-20px">
            <PBFEContent
              label="Biller Code"
              value={data?.data?.update_obj?.account_id}
            />
            <PBFEContent
              label="Biller Name"
              value={data?.data?.update_obj?.account_name}
            />
            <PBFEContent
              label="Channel Account ID"
              value={data?.data?.update_obj?.channel_account_id}
            />
            <div></div>
            <PBFEContent
              label="Biller Status"
              value={data?.data?.update_obj?.biller_status}
            />
            <PBFEContent
              label="Bank Code"
              value={data?.data?.update_obj?.bank_code}
            />
            <PBFEContent
              label="Currency Code"
              value={data?.data?.update_obj?.currency_code}
            />
            <PBFEContent
              label="Activation Date"
              value={formateDate(data?.data?.update_obj?.activation_date)}
            />
          </div>
        }
      />
      <div className="h-10px bg-gray-100"></div>
      {/* <div className="px-12 pb-xxs pt-xxs my-6">
        <div className="text-primarydark font-bold text-14px mb-2">REMARK</div>
        <div className="p-4 bg-gray-100 text-14px break-words">
          {data?.remark}
        </div>
      </div> */}
    </div>
  );
};

export const PBFEApprovalDetail = ({
  setRouteName,
  setDataRequest,
  setActionAppbar,
  actionAppbar,
  serviceType,
  prevPath,
  setPrevPath,
  type = "",
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const {
    data,
    setData,
    loading,
    error,
    originalData: originDataQuery,
  } = useQuery(
    makerCheckerService.getMakerCheckerInfoById,
    {
      request_id: params.request_id,
    },
    {},
    [],
    { checkFirstLoad: true }
  );

  useEffect(() => {
    if (serviceType === "history") {
      setActionAppbar("HISTORY");

      let title = "";
      let pathName = "";
      let id = "";

      if (type === "customer") {
        title = "CUSTOMER";
        pathName = "/customer";
        id = params.customer_id;
      } else {
        title = "ACCOUNT";
        pathName = "/account";
        id = params.account_id;
      }

      setRouteName([
        {
          title: title,
          path: `${pathName}`,
        },
        {
          title: id,
          path: `${pathName}/${id}`,
        },
        {
          title: `HISTORY: ${data?.transaction_reference}`,
        },
      ]);
    } else {
      let splitPath = location.pathname.split("/");
      splitPath = splitPath.filter((path) => path);

      let newPath = "";
      let newTitle = "";

      if (splitPath.length === 3) {
        newPath = `/${splitPath[0]}/${splitPath[1]}`;
      } else if (splitPath.length === 2) {
        newPath = `/${splitPath[0]}`;
      }

      if (
        ["/waitingforapprove/allrequest", "/myrequest/allrequest"].includes(
          newPath
        )
      ) {
        newTitle = "ALL REQUEST";
      } else if (newPath === "/myrequest") {
        newTitle = "MY REQUEST";
      } else if (newPath === "/waitingforapprove") {
        newTitle = "WAITING FOR APPROVAL";
      }

      setRouteName([
        {
          title: newTitle,
          path: newPath,
        },
        { title: data?.transaction_reference },
      ]);

      let newActionAppBar = "";

      if (serviceType === "maker") {
        newActionAppBar = "MAKER_DETAIL";
      } else {
        newActionAppBar = "CHECKER_DETAIL";
      }

      setActionAppbar(newActionAppBar);

      console.log("Approve detail recived data ", {
        data,
        newActionAppBar,
        type,
      });

      if (data) {
        setDataRequest({
          customer_name:
            data?.transaction_type === "UPDATESERVICEADDRESS"
              ? data?.customer_name
              : data?.data?.display_obj?.customer_name,
          account_name: data?.data?.display_obj?.account_name,
          customer_id: data.customer_id,
          account_id: data.account_id ? data.account_id : null,
          transaction_id: data?.transaction_id,
          transaction_type: data.transaction_type,
          raw_data: data?.data?.display_obj?.raw_data,
          update_obj: data?.data?.update_obj,
          status: data.status,
          maker: data?.maker,
          file_upload_id: data?.file_upload_id,
          hide_checker_action: data?.hide_checker_action,
          //  hide_checker_action: "N",
          hide_maker_action: data?.hide_maker_action,
          originalData: data,
        });
      }
    }
  }, [data]);

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;

  const transaction_type = data.transaction_type;

  if (transaction_type === "NEWSERVICE") {
    return <PBFERenderNewService data={data} />;
  } else if (transaction_type === "UPDATESERVICE") {
    return <PBFERenderUpdateService data={data} />;
  } else if (transaction_type === "NEWOFFER") {
    return <PBFERenderNewOffer data={data} />;
  } else if (transaction_type === "UPDATEOFFER") {
    return <PBFERenderUpdateOffer data={data} />;
  } else if (transaction_type === "TRANSACTION") {
    return <UploadMakerChecker data={data} />;
  } else if (transaction_type === "UPDATESERVICEADDRESS") {
    return <PBFERenderUpdateServiceInfo data={data} />;
  } else if (transaction_type === "NEWBUNDLEOFFER") {
    return <PBFERenderNewBundleOffer data={data} />;
  } else if (transaction_type === "UPDATEBUNDLEOFFER") {
    return <PBFERenderUpdateBundleOffer data={data} />;
  } else if (transaction_type === "NEWEPPBILLER") {
    return <PBFERenderNewEPPBiller data={data} />;
  } else {
    return null;
  }
};
