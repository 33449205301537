import {
  MIN_VALUE,
  MAX_VALUE,
  FEE_TABLE_TYPE,
  STEP,
  NULL_DATE,
} from "../constants/constant";
import { format } from "date-fns";
import {
  checkDateInvalid,
  checkFormToValue,
  checkFromToDate,
  FunctionCheckDateLength,
  FunctionCheckDateValidCustom,
  getValidateError,
  TEXT_FIELD_ERROR_DATE_FUTURE,
} from "../functions";
import _ from "lodash";
import DownloadIcon from "../assets/icon/download.svg";
import ErrorIcon from "../assets/icon/error.svg";
import uploadService from "../api/uploadService";
import { useEffect, useState } from "react";
import reportService from "../api/reportService";
import FileSaver from "file-saver";
import { AlertModal } from "../components/Panel/Modal/alertModal";
import Tooltip from "@mui/material/Tooltip";
import { DataArrayRounded } from "@mui/icons-material";

export const checkInvalidPast = (currentDate = new Date()) => {
  const today = formatDateApi();
  const currentFormatDate = formatDateApi(new Date(currentDate));

  if (today > currentFormatDate) {
    return true;
  }

  return false;
};

export const formatNumber = (value) => {
  if (value === MIN_VALUE) return "MIN";
  else if (value === MAX_VALUE) return "MAX";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const mappingValue = (value, defaultValue) => {
  if (value) {
    return value;
  }
  return defaultValue;
};

export const formatNumberComma = (value) => {
  if (!value) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const adjustStringLength = (str = "", length = 999) => {
  if (!str) return "";
  else if (str?.length <= length) return str;
  return str?.substring(0, 17) + "...";
};

export const getClearValueObject = (data) => {
  const keys = Object.keys(data);
  let newObject = { value: [] };

  keys.forEach((key) => {
    if (data[key]) {
      if (key === "qualifiers") {
        const keyQualifers = Object.keys(data.qualifiers);
        keyQualifers.forEach((qualifier) => {
          newObject = {
            ...newObject,
            qualifiers: {
              ...newObject?.qualifiers,
              [qualifier]: [],
            },
          };
        });
      } else if (key === "value") {
        data.value.forEach((val) => {
          const keyValue = Object.keys(val);
          keyValue.forEach((valKey) => {
            newObject = {
              ...newObject,
              value: [{ ...val[valKey], [valKey]: "" }],
            };
          });
        });
      } else {
        newObject = {
          ...newObject,
          [key]: "",
        };
      }
    }
  });

  return newObject;
};

export const getWordSpaceUppercase = (word) => {
  const splitWord = word.split(" ");
  let newWord = "";
  if (splitWord.length > 1) {
    splitWord.forEach((itemSplitWord) => {
      newWord += `${_.capitalize(itemSplitWord)} `;
    });
    return newWord;
  }

  return _.capitalize(word);
};

export const formatEmpty = (value, emptyValue = "-") => {
  if (value === 0) {
    return 0;
  }
  if (!value) {
    return emptyValue;
  }
  return value;
};

export const formatNumberMinReverse = (value) => {
  if (value === "MIN") return 0;
  return value;
};

export const formatNumberMaxReverse = (value) => {
  if (value === "MAX") return 0;
  return value;
};

export const formatNumberMin = (value, disabledComma = false) => {
  if (value === MIN_VALUE) {
    return "MIN";
  }

  if (disabledComma) {
    return value;
  }
  return formatNumberComma(value);
};

export const formatNumberMax = (value, disabledComma = false) => {
  if (value >= MAX_VALUE) {
    return "MAX";
  }

  if (disabledComma) {
    return value;
  }
  return formatNumberComma(value);
};

export const convertRequestType = (type) => {
  let convert_type = "";

  if (type === "New Customer / Account Pricelist") {
    convert_type = "NEWSERVICE";
  } else if (type === "New Customer / Account Offer") {
    convert_type = "NEWOFFER";
  } else if (type === "Update Customer / Account Pricelist") {
    convert_type = "UPDATESERVICE";
  } else if (type === "Update Customer / Account Offer") {
    convert_type = "UPDATEOFFER";
  } else if (type === "New Customer / Account Bundle Offer") {
    convert_type = "NEWBUNDLEOFFER";
  } else if (type === "Update Customer / Account Bundle Offer") {
    convert_type = "UPDATEBUNDLEOFFER";
  }

  return convert_type;
};

export const isStepTier = (value) => {
  return value === STEP || value === "asd";
};

export const isValidDate = (date) => {
  if (date === NULL_DATE) return true;
  const expireDate = Date.parse(date);
  const now = new Date().setHours(0, 0, 0, 0, 0);
  if (expireDate < now) return false;
  return true;
};

export const formatField = (value) => {
  if (value === undefined) return "-";
  if (!isNaN(value)) return formatNumber(value);
  if (value?.includes("T00:00:00")) return formateDate(value);
  return value;
};

export const fomat = (value, type = 0) => {};

export const calculateRowSpan = (value) => {
  return value + 1;
};

export const formatPercent = (value) => {
  return value + "%";
};

export const formatNone = (value) => {
  return value;
};

export const checkValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getDateOnly = (date = "") => {
  const myDate = date.split("T");
  if (myDate.length > 0) {
    return myDate[0].slice(0, 10);
  }
  return date;
};

export const convertErrorApi = (message = "") => {
  const splitMessage = message.split(":");
  if (splitMessage.length > 1) {
    return splitMessage[1];
  }

  return splitMessage[0];
};

export const getValidateRateType = (value) => {
  if (
    (value.rate && value.rate_type === "-") ||
    (value.rate && !value.rate_type)
  ) {
    return { error: true };
  }
  return { error: false };
};

export const getGroupIndex = (group, index) => {
  let currentIndex = index;
  for (let newIndex = currentIndex - 1; newIndex >= 0; newIndex--) {
    if (newIndex === 0) {
      return -1;
    }

    if (group?.fee_charge[newIndex]?.isRemove === false) {
      currentIndex = newIndex;
      break;
    }
  }

  return currentIndex;
};

export const getCheckValidateThreeMonth = (from, to) => {
  const dateFrom = new Date(from);
  const dateTo = new Date(to);

  if (dateTo.getMonth() - dateFrom.getMonth() > 3) {
    return true;
  }

  return false;
};

export const getFeeChargeError = (service) => {
  let checkListChargeError = [];
  for (let index = 0; index < service.charge_group.length; index++) {
    const group = service.charge_group[index];
    for (let jedex = 0; jedex < group.fee_charge.length; jedex++) {
      const charge = group.fee_charge[jedex];

      if (charge.isRemove === true) {
        continue;
      }

      charge.from_date = getDateOnly(charge.from_date);
      charge.to_date = getDateOnly(charge.to_date);

      const validateLengthValidFromDate = FunctionCheckDateLength(
        charge.from_date,
        "from_date"
      );

      const validateLengthValidToDate = FunctionCheckDateLength(
        charge.to_date,
        "to_date"
      );

      const validateDateValidFromDate = FunctionCheckDateValidCustom(
        charge.from_date,
        "from_date"
      );

      // const isPastFromDate =
      //   new Date(charge.from_date).setHours(0, 0, 0, 0) <
      //   new Date().setHours(0, 0, 0, 0);

      const validateDateValidToDate = FunctionCheckDateValidCustom(
        charge.to_date,
        "to_date"
      );

      let getErrorFromDate = checkFromToDate(
        charge?.from_date,
        charge?.to_date,
        group?.fee_charge?.length > 0 && jedex > 0
          ? group?.fee_charge[getGroupIndex(group, jedex)]?.to_date
          : null,
        "from_date"
      );

      let getErrorToDate = checkFromToDate(
        charge?.from_date,
        charge?.to_date,
        group?.fee_charge?.length > 0 && jedex > 0
          ? group?.fee_charge[getGroupIndex(group, jedex)]?.to_date
          : null,
        "to_date"
      );

      if (
        validateLengthValidFromDate.error ||
        validateLengthValidToDate.error ||
        validateDateValidFromDate.error ||
        validateDateValidToDate.error ||
        getErrorFromDate ||
        getErrorToDate
      ) {
        checkListChargeError.push(true);
      }

      const hasQualifer = charge?.fee_value?.conditions?.length > 0;

      if (!hasQualifer) {
        const tier = charge.fee_value.tier;
        for (let vedex = 0; vedex < charge.fee_value.value.length; vedex++) {
          const value = charge.fee_value.value[vedex];
          checkListChargeError = getFeeValueError(
            charge,
            value,
            tier,
            vedex,
            checkListChargeError,
            charge.fee_value.value
          );
        }
      } else {
        for (
          let codex = 0;
          codex < charge?.fee_value?.conditions?.length;
          codex++
        ) {
          const condition = charge?.fee_value?.conditions[codex];
          const tier = condition.tier;
          for (let vedex = 0; vedex < condition.value.length; vedex++) {
            const value = condition.value[vedex];
            checkListChargeError = getFeeValueError(
              charge,
              value,
              tier,
              vedex,
              checkListChargeError,
              condition.value,
              true,
              condition
            );
          }
        }
      }
    }
  }

  return checkListChargeError;
};

export const getValidateOfferError = (value) => {
  const listError = [];

  value.from_date = getDateOnly(value.from_date);
  value.to_date = getDateOnly(value.to_date);

  const validateLengthValidFromDate = FunctionCheckDateLength(
    value.from_date,
    "from_date"
  );

  const validateLengthValidToDate = FunctionCheckDateLength(
    value.to_date,
    "to_date"
  );

  const validateDateValidFromDate = FunctionCheckDateValidCustom(
    value.from_date,
    "from_date"
  );

  const validateDateValidToDate = FunctionCheckDateValidCustom(
    value.to_date,
    "to_date"
  );

  let getErrorFromDate = checkFromToDate(
    value?.from_date,
    value?.to_date,
    null,
    "from_date"
  );

  let getErrorToDate = checkFromToDate(
    value?.from_date,
    value?.to_date,
    null,
    "to_date"
  );

  if (
    validateLengthValidFromDate.error ||
    validateLengthValidToDate.error ||
    validateDateValidFromDate.error ||
    validateDateValidToDate.error ||
    getErrorFromDate ||
    getErrorToDate
  ) {
    listError.push(true);
  }

  return listError;
};

export const getFeeValueError = (
  charge,
  value,
  tier,
  vedex,
  checkListChargeError = [],
  valueArr = [],
  hasQualifer = false,
  condition
) => {
  let newCheckListError = [...checkListChargeError];

  if (tier !== "NO") {
    var getErrorFrom = getValidateError(
      "from",
      value.from,
      tier,
      "to",
      value.to
    );

    var getErrorTo = getValidateError("to", value.to, tier);

    var getPrvErrorFrom =
      valueArr?.length > 1 &&
      vedex > 0 &&
      checkFormToValue(
        value.from,
        valueArr[vedex - 1].to ? valueArr[vedex - 1].to : 0
      );

    var getMinTier = getValidateError(
      "min_tier",
      value.min_tier,
      tier,
      "max_tier",
      value.max_tier
    );

    var getMaxTier = getValidateError("max_tier", value.max_tier, tier);
  }

  const getFixAmountError = getValidateError(
    "fix_amount",
    value.fix_amount,
    tier
  );

  const getRateError = getValidateError(
    "rate",
    value.rate,
    tier,
    "rate_type",
    value.rate_type
  );

  const getRateTypeError = getValidateRateType(value);

  const getMinError = getValidateError(
    "min",
    !hasQualifer ? charge?.fee_value?.min : condition?.min,
    tier,
    "max",
    !hasQualifer ? charge?.fee_value?.max : condition?.max
  );

  const getMaxError = getValidateError("max", charge.fee_value.max, tier);

  if (
    getPrvErrorFrom ||
    getFixAmountError?.error ||
    getErrorTo?.error ||
    getErrorFrom?.error ||
    getRateError?.error ||
    getRateTypeError?.error ||
    getMinError?.error ||
    getMaxError?.error ||
    getMinTier?.error ||
    getMaxTier?.error
  ) {
    newCheckListError.push(true);
  }

  return newCheckListError;
};

export const isValidErrorApi = (error) => {
  if (error && error.response.data.status.description) {
    return true;
  }
  return false;
};

export const formatDateApi = (dateString = new Date(), nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  // if (dateString === "9999-12-31T00:00:00") return "";
  // if (!checkValidDate(new Date(dateString))) {
  //   dateString = new Date();
  // }
  // let splitTime = new Date(dateString).toISOString()?.split("T");
  // let dateSplit = splitTime ? splitTime[0]?.split("-") : "";

  // let timeSplit = splitTime ? splitTime[1]?.split(":") : "";

  // return dateSplit[0] + "-" + dateSplit[1] + "-" + dateSplit[2] + "T00:00:00";

  const currentDate = `${format(new Date(dateString), "yyyy-MM-dd")}T00:00:00`;
  return currentDate;
};

export const formateDate = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
};

export const formateDateWithOutNullValue = (dateString) => {
  if (dateString === "" || dateString === null || !dateString) return "";
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
};

export const formateDateWithTime = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";
  return (
    dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0] + " " + splitTime[1]
  );
};

export const yesNoMapping = (value) => {
  if (value === "Y") {
    return "YES";
  } else if (value === "N") {
    return "NO";
  }
  return value;
};
export const formateDateWithTimeHourMintuesOneline = (dateString) => {
  return (
    <div className="w-28">{formateDateWithTimeHourMintues(dateString)}</div>
  );
};

export const formateDateWithTimeHourMintues = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";
  let splitTime = dateString?.split("T");
  let dateSplit = splitTime ? splitTime[0]?.split("-") : "";

  let timeSplit = splitTime ? splitTime[1]?.split(":") : "";

  return (
    dateSplit[2] +
    "/" +
    dateSplit[1] +
    "/" +
    dateSplit[0] +
    " " +
    timeSplit[0] +
    ":" +
    timeSplit[1]
  );
};

export const checkFeeChargeAllDelete = (fee_charge = []) => {
  return fee_charge.every((charge) => charge?.action === "DELETE");
};

export const onScrollToTop = (id) => {
  const doc = document.getElementById(id);
  if (doc) {
    window.scrollTo(0, -100);
  }
};

export const getFeeChargeOfGroup = (charge_group = []) => {
  let fee_charge = [];
  charge_group?.forEach((charge) => {
    fee_charge = [...fee_charge, ...charge.fee_charge];
  });

  return fee_charge;
};

export const ColorStatus = (status) => {
  if (status === "Pending")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (status === "Approved")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  if (status === "Closed" || status === "Rejected") {
    return <div className="bg-gray-300 rounded-full w-9px h-9px"></div>;
  }
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const TwoLineColumn = (context, row) => {
  const processContext = context.split("|");

  if (processContext?.length < 1) return <div></div>;

  if (row?.request_type === "New ePP Biller") {
    return (
      <div>
        <div>{row?.request_type}</div>
        <Tooltip title={`${row?.customer_name} / ${row?.remark || ""}`}>
          <div className="text-textscbdark text-label">
            {" "}
            {adjustStringLength(
              `${row?.customer_name} / ${row?.remark || ""}`
            )}{" "}
          </div>
        </Tooltip>
      </div>
    );
  }
  return (
    <div>
      <div>{processContext[0]}</div>
      {processContext.length > 1 && (
        <Tooltip title={processContext[1]}>
          <div className="text-textscbdark text-label">
            {" "}
            {adjustStringLength(processContext[1])}{" "}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const ReportColorStatus = (status) => {
  if (_.upperCase(status) === "PROCESSING")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (_.upperCase(status) === "SUCCESS")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const UploadColorStatus = (status) => {
  if (status === "PROCESSING")
    return <div className="bg-yellow-500 rounded-full w-9px h-9px"></div>;
  if (status === "PROCESSED" || status === "SUCCESS")
    return <div className="bg-green-500 rounded-full w-9px h-9px"></div>;
  return <div className="bg-red-500 rounded-full w-9px h-9px"></div>;
};

export const DownloadCell = (record) => {
  const [openError, setOpenError] = useState(false);
  const [errorApi, setReturnErrorApi] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setOpenError(false);
      setReturnErrorApi("");
    }, 1000);
  }, [openError]);

  const onClickDownLoad = async () => {
    try {
      const response = await uploadService.getUploadDownload(
        record.id,
        `?type=${record.type}`
      );

      if (response.status === 200) {
        let filename = response.headers["content-disposition"].replace(
          "attachment; filename=",
          ""
        );
        filename = filename.replace(/['"]+/g, "");
        downloadFile(response.data, filename);
      } else {
        setReturnErrorApi(response?.statusText);
        setOpenError(true);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  if (record?.status !== "PROCESSED") {
    return null;
  }

  return (
    <div
      onClick={onClickDownLoad}
      target={"_blank"}
      className="flex text-textscb cursor-pointer"
    >
      <AlertModal
        open={openError}
        title={"Error"}
        description={errorApi}
        action={() => setOpenError(false)}
      />
      <img className="mr-4" src={DownloadIcon}></img>
      {record.amount}
    </div>
  );
};

export const downloadFile = (fileBlob, filename) => {
  const blob = new Blob([fileBlob], {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, filename);
};

export const validateFormatDate = (date) => {
  // var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

  const myDate = new Date(date);
  const year = myDate.getFullYear();

  if (String(year).length < 4) {
    return true;
  }

  return false;
};

export const formatAction = (action) => {
  return <div>{_.capitalize(action)}</div>;
};

export const DownloadCellCommonWithOutText = (record) => {
  const [openError, setOpenError] = useState(false);
  const [errorApi, setReturnErrorApi] = useState("");

  const onClickDownLoad = async () => {
    try {
      if (record.status === "FAILED") {
        setOpenError(true);
        return;
      }

      const response = await reportService.downloadReport(record.request_id);
      let filename = response.headers["content-disposition"].replace(
        "attachment; filename=",
        ""
      );
      return downloadFile(response.data, filename);
    } catch (error) {
      if (error.response.status === 400) {
        setReturnErrorApi(convertErrorApi("Data not found"));
        setOpenError(true);
      }
    }
  };

  if (record === undefined || record === null || record === "") {
    return <></>;
  }

  if (record.status === "FAILED") {
    return (
      <div>
        <AlertModal
          open={openError}
          title={"Priced Transaction"}
          description={record.fail_reason}
          action={() => setOpenError(false)}
        />
        <img onClick={onClickDownLoad} className="" src={ErrorIcon} />
      </div>
    );
  }

  if (record.status !== "SUCCESS") {
    return null;
  }

  return (
    <div>
      <AlertModal
        open={openError}
        title={"Priced Transaction"}
        description={errorApi}
        action={() => setOpenError(false)}
      />
      <div
        onClick={onClickDownLoad}
        target={"_blank"}
        className="flex text-textscb cursor-pointer"
      >
        <img className="" src={DownloadIcon}></img>
      </div>
    </div>
  );
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const DownloadCellCommon = (record) => {
  const onClickDownLoad = async () => {
    try {
      const response = await reportService.downloadReport(record.request_id);
      let filename = response.headers["content-disposition"].replace(
        "attachment; filename=",
        ""
      );
      downloadFile(response.data, filename);
    } catch (error) {
      console.error("download error", error);
    }
  };

  return (
    <div
      onClick={onClickDownLoad}
      target={"_blank"}
      className="flex text-textscb cursor-pointer"
    >
      <img className="mr-4" src={DownloadIcon}></img>
      DOWNLOAD
    </div>
  );
};

export const tableBorderBottomStyle = (type) => {
  return {
    borderBottom: type === FEE_TABLE_TYPE.Report ? "" : "4px solid white",
  };
};

export const isRefreshTokenNearlyExpired = () => {};

export const saveTokenToLocalStorage = (data) => {
  sessionStorage.setItem("access_token", data.access_token);
  sessionStorage.setItem("expires_in", data.expires_in);
  sessionStorage.setItem("refresh_token", data.refresh_token);
  sessionStorage.setItem("refresh_expires_in", data.refresh_expires_in);
  sessionStorage.setItem("expired_at", getExpiredDate(data.expires_in));
  sessionStorage.setItem(
    "refresh_expired_at",
    getExpiredDate(data.refresh_expires_in)
  );
  sessionStorage.setItem(
    "plan_refresh_token_expired_at",
    getExpiredDate(data.expires_in) - 1000 * 60 * 2
  );
};

export const getExpiredDate = (expires_in) => {
  return Date.now() + expires_in * 1000;
};

export const getUserFromStorage = () => {
  let data = { user: null };

  let getUserStorage = sessionStorage.getItem("user");
  if (getUserStorage) {
    data.user = JSON.parse(getUserStorage).user;
  }
  return data.user;
};

export const getTokenFromLocalStorage = () => {
  const access_token = sessionStorage.getItem("access_token");
  const expires_in = sessionStorage.getItem("expires_in");
  const refresh_token = sessionStorage.getItem("refresh_token");
  const refresh_expires_in = sessionStorage.getItem("refresh_expires_in");

  return {
    access_token,
    expires_in,
    refresh_token,
    refresh_expires_in,
    info: parseJwt(access_token),
  };
};

export const saveUserLogin = (isLoggedIn) => {
  if (isLoggedIn) {
    sessionStorage.setItem("isLoggedIn", true);
  } else {
    sessionStorage.removeItem("isLoggedIn");
  }
};

export const checkUserLogin = () => {
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  return isLoggedIn !== undefined && isLoggedIn !== null;
};

export const parseJwt = (token) => {
  if (!token) return null;
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const checkCurrentDate = (
  from_date = new Date(),
  to_date = new Date()
) => {
  const today = new Date();
  const formatToday = format(today, "yyyy-MM-dd");
  const formatFromDate = format(from_date, "yyyy-MM-dd");
  return formatToday >= formatFromDate;
};

export const checkBackDate = (from_date) => {
  const today = new Date();
  const formatToday = format(today, "yyyy-MM-dd");
  //const formatFromDate = format(from_date, "yyyy-MM-dd");
  return formatToday >= from_date;
};

export const pad = (number) => {
  if (parseInt(number) < 10) {
    return "0" + parseInt(number);
  }
  return number;
};

export const formatDateCheckValid = (date) => {
  if (date) {
    const thisDate = new Date(date);
    return (
      thisDate?.getFullYear() +
      "-" +
      pad(thisDate?.getMonth() + 1) +
      "-" +
      pad(thisDate?.getDate())
    );
  } else {
    return "";
  }
};

export const checkDebitProfileFutureDate = (currentRow = 0, profiles = []) => {
  let currentIndex = 0;

  if (profiles.length > 1) {
    if (currentRow === 0) {
      currentIndex = 1;
    } else if (currentRow === 1) {
      currentIndex = 0;
    }

    const notCurrentProfile = profiles[currentIndex];
    const currentProfile = profiles[currentRow];

    if (
      formatDateApi(new Date(currentProfile.from_date)) >=
        formatDateApi(new Date(notCurrentProfile.from_date)) &&
      formatDateApi(new Date(currentProfile.from_date)) <=
        formatDateApi(new Date(notCurrentProfile.to_date))
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const prePareService = (service) => {
  return {
    ...service,
    charge_group: service.charge_group.map((group) => {
      return {
        ...group,
        fee_charge: group.fee_charge.map((charge) => {
          return {
            ...charge,
            isError: false,
            isRemove: charge.action === "DELETE",
            checkCurrentDate: checkCurrentDate(
              new Date(charge.from_date),
              new Date(charge.to_date)
            ),
          };
        }),
      };
    }),
  };
};

export const prePareOffer = (offer) => {
  return {
    ...offer,
    checkCurrentDate: checkCurrentDate(
      new Date(offer.from_date),
      new Date(offer.to_date)
    ),
  };
};

export const prePareBundleOffer = (data) => {
  return {
    ...data,
    checkCurrentDate: checkCurrentDate(
      new Date(data.from_date),
      new Date(data.to_date)
    ),
  };
};

export const checkErrorDate = (dataService) => {
  const currentService = dataService;
  let checkErrorList = [];

  currentService.charge_group.forEach((group, groupIndex) => {
    group.fee_charge
      .filter(
        (item) => item?.isRemove === false && item.hasOwnProperty("isRemove")
      )
      .forEach((charge, index) => {
        const filter_not_self = group.fee_charge
          .filter((localCharge, f_index) => {
            return (
              localCharge?.isRemove === false &&
              localCharge?.hasOwnProperty("isRemove")
            );
          })
          .filter((item, f_index) => f_index !== index);

        const self_from_date = new Date(charge.from_date);
        // const self_to_date = new Date(charge.to_date);

        if (filter_not_self.length > 0) {
          for (let f_i = 0; f_i < filter_not_self.length; f_i++) {
            const not_self = filter_not_self[f_i];
            const not_self_from_date = new Date(not_self.from_date);
            const not_self_to_date = new Date(
              not_self.to_date === "9999-12-31T00:00:00" ||
              not_self.to_date === "" ||
              not_self.to_date === null
                ? "9999-12-31"
                : not_self.to_date
            );

            if (
              !checkDateInvalid(self_from_date) &&
              !checkDateInvalid(not_self_from_date) &&
              !checkDateInvalid(not_self_to_date)
            ) {
              if (
                formatDateApi(self_from_date) >=
                  formatDateApi(not_self_from_date) &&
                formatDateApi(self_from_date) <= formatDateApi(not_self_to_date)
              ) {
                checkErrorList = [
                  ...checkErrorList,
                  {
                    index: index,
                    groupIndex,
                    error: true,
                    message: `${String(
                      group.charge_code
                    ).toUpperCase()} ${TEXT_FIELD_ERROR_DATE_FUTURE}`,
                    field_name: "overlap_date",
                    field_value: [],
                    show: true,
                  },
                ];
                return;
              }
            }
          }
        }
      });
  });
  return checkErrorList;
};

export const returnMessageDuplicate = (index, groupIndex, group, priority) => {
  return {
    index: index,
    groupIndex,
    error: true,
    message: `Charge Code ${group.charge_code} priority ${priority} is duplicate`,
    field_name: "priority",
    field_value: priority,
    show: true,
  };
};

export const returnMessageDuplicateQualifer = (
  index,
  groupIndex,
  charge_code,
  qualiferList = []
) => {
  return {
    error: true,
    message: `Charge Code ${charge_code} qualifer ${qualiferList.join(
      ","
    )} is duplicate`,
    field_name: `qualifer`,
    field_value: qualiferList,
    show: true,
  };
};

const toFindDuplicates = (arry) =>
  arry.filter((item, index) => arry.indexOf(item) !== index);

export const checkDuplicatePriority = (dataService, validate = false) => {
  const currentService = dataService;
  let checkErrorList = [];
  var isError = false;
  currentService?.charge_group.forEach((group, groupIndex) => {
    group?.fee_charge?.forEach((charge, index) => {
      const fee_value = charge.fee_value;
      const hasQualifer = fee_value.hasOwnProperty("conditions");
      if (hasQualifer) {
        let tempPriority = [];
        tempPriority = fee_value.conditions.map((condition) => {
          const hasPriority = condition.hasOwnProperty("priority");
          if (hasPriority && condition?.priority !== "") {
            return String(condition.priority);
          }
          return null;
        }, []);

        const uniquePriority = toFindDuplicates(
          tempPriority.filter((item) => item !== null)
        );
        if (validate) {
          for (let i = 0; i < tempPriority.length; i++) {
            if (
              isNaN(tempPriority[i]) ||
              tempPriority[i] === "" ||
              tempPriority[i] < 1
            ) {
              isError = true;
              break;
            }
          }
        } else {
          checkErrorList = [
            ...checkErrorList,
            ...uniquePriority.map((item) => {
              return {
                ...returnMessageDuplicate(index, groupIndex, group, item),
              };
            }),
          ];
        }
      }
    });
  });
  if (validate) {
    return isError;
  }

  return checkErrorList;
};

export const checkDuplicateQualifer = (dataService) => {
  const currentService = dataService;
  let checkErrorList = [];

  currentService?.charge_group.forEach((group, groupIndex) => {
    group?.fee_charge?.forEach((charge, chargeIndex) => {
      const fee_value = charge.fee_value;
      const hasQualifer = fee_value.hasOwnProperty("conditions");
      if (hasQualifer) {
        fee_value.conditions.forEach((condition, conditionIndex) => {
          const hasQualifer = condition.hasOwnProperty("qualifiers");
          if (hasQualifer) {
            const qualiferKeys = Object.keys(condition.qualifiers);
            const qualiferList = [];
            for (let index = 0; index < qualiferKeys.length; index++) {
              const key = qualiferKeys[index];
              const qualifier = condition.qualifiers[key];
              qualiferList.push(...qualifier);
            }
            checkErrorList = [
              ...checkErrorList,
              {
                isRemove: charge.isRemove || false,
                charge_code: group.charge_code,
                qualiferList: qualiferList,
                groupIndex,
                index: chargeIndex,
                feeChargeIndex: conditionIndex,
              },
            ];
          }
        }, []);
      }
    });
  });

  let existDuplicate = [];
  checkErrorList.forEach((qualifer, qualiferIndex) => {
    for (let index = 0; index < checkErrorList.length; index++) {
      const prevQualifer = checkErrorList[index];

      const checkCharge =
        `${qualifer.index}_${qualifer.groupIndex}` ===
        `${prevQualifer.index}_${prevQualifer.groupIndex}`;

      if (
        prevQualifer.charge_code === qualifer.charge_code &&
        checkCharge &&
        !qualifer.isRemove
      ) {
        let qualiferExist = false;
        if (index !== qualiferIndex) {
          const curr = qualifer.qualiferList;
          const prev = prevQualifer.qualiferList;
          let countMatching = 0;

          const firstRow = curr.length > prev.length ? curr : prev;
          const secondRow = curr.length > prev.length ? prev : curr;

          for (let i = 0; i < firstRow.length; i++) {
            for (let j = 0; j < secondRow.length; j++) {
              if (firstRow[i] === secondRow[j]) {
                countMatching++;
              }
            }
          }

          qualiferExist = firstRow.length === countMatching;

          if (qualiferExist) {
            if (existDuplicate.length > 0) {
              const findDuplicateExistIndex = existDuplicate.findIndex(
                (dup) => dup.charge_code === qualifer.charge_code
              );
              if (findDuplicateExistIndex !== -1) {
                existDuplicate[findDuplicateExistIndex] = qualifer;
              } else {
                existDuplicate.push(qualifer);
              }
            } else {
              existDuplicate.push(qualifer);
            }
          }
        }
      }
    }
  });

  existDuplicate = existDuplicate.map((dup) => {
    return returnMessageDuplicateQualifer(
      dup.groupIndex,
      dup.index,
      dup.charge_code,
      dup.qualiferList
    );
  });

  return existDuplicate;
};

export const getErrorToDateMax = (debitProfile = []) => {
  if (debitProfile.length > 0) {
    const prevTodate = formatDateApi(
      debitProfile[debitProfile.length - 1].to_date,
      "9999-12-31T00:00:00"
    );
    if (
      prevTodate === "9999-12-31T00:00:00" ||
      prevTodate === null ||
      prevTodate === ""
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const mappingUT10012 = (fee_charge) => {
  return fee_charge?.map((feeCharge, index) => {
    let newQualifer = {
      charge_type: feeCharge?.qualifiers?.charge_type,
      channel: feeCharge?.qualifiers?.channel,
      zone: feeCharge?.qualifiers?.zone,
      ...feeCharge.qualifiers,
    };

    return {
      qualifiers: newQualifer,
      ...feeCharge,
      fee_value: {
        ...feeCharge.fee_value,
        conditions: feeCharge?.fee_value?.conditions.map((condition) => {
          let newQualifer = {
            charge_type: condition?.qualifiers?.charge_type,
            channel: condition?.qualifiers?.channel,
            zone: condition?.qualifiers?.zone,
            ...condition.qualifiers,
          };
          condition.qualifiers = newQualifer;

          return condition;
        }),
      },
    };
  });
};

/// Date functiion

const checkMaxTodate = (to_date) => {
  if (to_date === "9999-12-31T00:00:00" || to_date === "" || to_date === null) {
    return new Date();
  }

  return new Date(to_date);
};

const getFormateDate = (date) => {
  if (checkDateInvalid(new Date(date))) {
    return date;
  }
  return formatDateApi(date);
};

export const getFromDateFutureRate = (from_date, to_date) => {
  const today = `${format(new Date(), "yyyy-MM-dd")}T00:00:00`;

  let currentFromDate = from_date;
  let currentToDate = to_date;

  if (checkDateInvalid(new Date(to_date))) {
    currentToDate = "";
  }
  if (checkDateInvalid(new Date(from_date))) {
    currentFromDate = "";
  } else if (
    from_date < today &&
    (to_date === "9999-12-31T00:00:00" || to_date === "")
  ) {
    currentToDate = new Date();
    currentToDate = getFormateDate(currentToDate);
  } else if (from_date >= today) {
    if (to_date && !(to_date === "9999-12-31T00:00:00" || to_date === "")) {
      const newToDate = checkMaxTodate(to_date);
      currentToDate = new Date(newToDate);
      currentToDate.setDate(new Date(newToDate).getDate());
      currentToDate = getFormateDate(currentToDate);
    } else {
      currentToDate = new Date(from_date);
      currentToDate.setDate(new Date(from_date).getDate() + 1);
      currentToDate = getFormateDate(currentToDate);
    }
  }

  return { currentFromDate, currentToDate };
};

export const WrapTextEnter = (text) => {
  return <div className="w-36 break-words">{text.replaceAll(",", "\n")}</div>;
};
export const WrapTextEnterEmail = (text) => {
  return <div className="w-36 break-words">{text.replaceAll(",", ",\n")}</div>;
};

export const fillZeroIfEmpty = (data = [], haveBeforeAfter = false) => {
  var temp = [...data];

  if (haveBeforeAfter) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].after_charge.fee_charge) {
        if (data[i].after_charge.fee_charge.fee_value?.conditions?.length > 0) {
          for (
            let k = 0;
            k < data[i].after_charge.fee_charge.fee_value.conditions.length;
            k++
          ) {
            if (
              data[i].after_charge.fee_charge.fee_value?.conditions[k]?.value
                ?.length < 1
            ) {
              temp[i].after_charge.fee_charge.fee_value?.conditions[
                k
              ].value.push({ fix_amount: 0 });
            }
          }
        }
      }
    }
  } else {
    for (let i = 0; i < data.length; i++) {
      if (data[i].fee_charge?.length > 0) {
        for (let j = 0; j < data[i].fee_charge.length; j++) {
          if (data[i].fee_charge[j].fee_value?.conditions?.length > 0) {
            for (
              let k = 0;
              k < data[i].fee_charge[j].fee_value.conditions.length;
              k++
            ) {
              if (
                data[i].fee_charge[j].fee_value?.conditions[k]?.value?.length <
                1
              ) {
                temp[i].fee_charge[j].fee_value?.conditions[k].value.push({
                  fix_amount: 0,
                });
              }
            }
          }
        }
      }
    }
  }
  return temp;
};

export const FunctionGetNumberFromString = (input) => {
  try {
    return input.replace(/[^0-9]/g, "");
  } catch (error) {
    console.error("FunctionGetNumberFromString : ", error);
  }
};

export const mappingForServiceInfo = (service_info) => {
  let ret = [];
  for (let i = 0; i < service_info.length; i++) {
    ret.push({
      edit: i,
      service_code: service_info[i].service_code,
      account_id: service_info[i].account_id,
      customer_full_name_thai: service_info[i].customer_full_name_thai,
      customer_branch_no: service_info[i].customer_branch_no,
      reg_addr_line:
        service_info[i].reg_addr_line1 +
        " " +
        service_info[i].reg_addr_line2 +
        " " +
        service_info[i].reg_addr_line3 +
        " " +
        service_info[i].reg_zip_code,
      contact_addr_line:
        service_info[i].contact_addr_line1 +
        " " +
        service_info[i].contact_addr_line2 +
        " " +
        service_info[i].contact_addr_line3 +
        " " +
        service_info[i].contact_zip_code,
      email: service_info[i].email,
      email_alert_flag: service_info[i].email_alert_flag,
    });
  }
  return ret;
};

// export const isValidEmail = (emails) => {
//   if (emails === undefined || emails === "") return true;
//   const array = emails.split(",");
//   for (let i = 0; i < array.length; i++) {
//     let reg =
//       /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
//     // console.log("array value", array[i]);
//     if (
//       reg.test(String(array[i])?.toLocaleLowerCase()) === false ||
//       !(
//         array[i]?.substring(0, 1)?.toLowerCase() !==
//         array[i]?.substring(0, 1)?.toUpperCase()
//       )
//     ) {
//       return false;
//     }
//     if (
//       !String(array[i])
//         .toLowerCase()
//         .match(
//           /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//         )
//     )
//       return false;
//   }
//   return true;
// };

export const isValidEmail = (emails) => {
  if (emails === undefined || emails === "") return true;
  const emailArray = emails.split(",");

  for (let i = 0; i < emailArray.length; i++) {
    const email = emailArray[i];

    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!reg.test(email)) {
      return false;
    }

    // if (email[0] === email[0].toUpperCase()) {
    //   return false;
    // }
  }

  return true;
};
